export const matchsetting = {
    dots: false,
    arrows: false,
    adaptiveHeight: true,
    infinite: false,
    lazyLoad: "ondemand",
  
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    // appendDots: dots => {
    //   return (
    //     <div>
    //       <ul>
    //         {dots.map((item, index) => {
  
    //           return (
    //             <li key={index}><div className={item?.props?.className === "slick-active"?"active-dot":"inactive-dot"} /></li>
    //           );
    //         })}
    //       </ul>
    //     </div>
    //   )
    // },
  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
       
          slidesToScroll: 1,
        },
      },
    ],
  };

  export const topnewssetting = {
    dots: true,
    arrows: false,
    adaptiveHeight: true,
    infinite: false,
    lazyLoad: "ondemand",
  
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  
  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
            
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  export const othernewssetting = {
    dots: false,
    arrows: false,
    adaptiveHeight: true,
    infinite: false,
    lazyLoad: "ondemand",
  
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
  
  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
            arrows:true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };