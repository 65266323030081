import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Divider, Table } from "antd";
import back from "../../assets/back.png";

import { AppLayout } from "../../Components";
import { useLocation, useNavigate } from "react-router-dom";
import useViewport from "../../Hooks/useViewPort";

import axios from "axios";
import { SERVER_URL } from "../../utils/server";
import { NewsContext } from "../../Context/NewsProvider";

const Details = () => {
  const { isMobile } = useViewport();
  const navigate = useNavigate();
  const {
    news,
    feed,
    footballnews,
    kabaddinews,
    newsloading,
    teams: Teams,
    players,
  } = useContext(NewsContext);
  const location = useLocation();
  const [scores, setScores] = useState({
    batting: [],
    bowling: [],
  });
  const teams = Teams?.filter((x) => x.type === "cricket");

  const getscoreboard = async () => {
    try {
      const data = await axios.get(
        SERVER_URL +
          `/live/score?id=${location?.state?.localteamscore?.fixture_id}`
      );
      setScores({
        batting: data?.data?.data?.batting?.data?.batting || [],
        bowling: data?.data?.data?.bowling?.data?.bowling || [],
      });
    } catch (e) {}
  };
  useEffect(() => {
    getscoreboard();
  }, []);

  const columns = [
    {
      title: "Batter",
      dataIndex: "player_id",
      width: 300,
      render: (text) => (
        <div className='table-text'>
          {
            players.filter(
              (x) => x.type === "cricket" && Number(x.id) === text
            )?.[0]?.firstname
          }{" "}
        </div>
      ),
    },
    {
      title: "R",
      dataIndex: "score",
      render: (text) => <div className='table-text'>{text}</div>,
    },
    {
      title: "B",
      dataIndex: "ball",
      render: (text) => <div className='table-text'>({text})</div>,
    },
    {
      title: "4s",
      dataIndex: "four_x",
      render: (text) => <div className='table-text'>{text}</div>,
    },
    {
      title: "6s",
      dataIndex: "six_x",
      render: (text) => <div className='table-text'>{text}</div>,
    },
    {
      title: "SR",
      dataIndex: "rate",
      render: (text) => <div className='table-text'>{text}</div>,
    },
  ];

  const columns1 = [
    {
      title: "Bowler",
      dataIndex: "player_id",
      width: 300,
      render: (text) => (
        <div className='table-text'>
          {
            players.filter(
              (x) => x.type === "cricket" && Number(x.id) === text
            )?.[0]?.firstname
          }{" "}
        </div>
      ),
    },
    {
      title: "R",
      dataIndex: "runs",
      render: (text) => <div className='table-text'>{text}</div>,
    },
    {
      title: "W",
      dataIndex: "wickets",
      render: (text) => <div className='table-text'>{text}</div>,
    },
    {
      title: "Over",
      dataIndex: "overs",
      render: (text) => <div className='table-text'>({text})</div>,
    },
    {
      title: "Econ",
      dataIndex: "rate",
      render: (text) => <div className='table-text'>{text}</div>,
    },
  ];

  const localteamscorebatting = scores?.batting?.filter(
    (x) => x.team_id === location?.state?.item?.localteam_id
  );
  const localteamscorebowling = scores?.bowling?.filter(
    (x) => x.team_id === location?.state?.item?.localteam_id
  );

  const visitorteamscorebatting = scores?.batting?.filter(
    (x) => x.team_id === location?.state?.item?.visitorteam_id
  );
  const visitorteamscorebowling = scores?.bowling?.filter(
    (x) => x.team_id === location?.state?.item?.visitorteam_id
  );

  return (
    <AppLayout>
      <div className='container'>
        <Breadcrumb
          separator='>>'
          items={[
            {
              title: "Home",
              onClick: () => {
                navigate(-1);
              },
              className: "cursor-pointer",
            },

            {
              title: `${location?.state?.item?.type} - ${
                teams?.filter(
                  (g) => g.id === location?.state?.item?.localteam_id
                )?.[0]?.code
              } vs ${
                teams?.filter(
                  (g) => g.id === location?.state?.item?.visitorteam_id
                )?.[0]?.code
              }`,
            },
          ]}
        />

        <Row gutter={16}>
          <Col span={isMobile ? 24 : 18}>
            <div style={{ marginTop: 10 }} className='space-between'>
              <div
                onClick={() => {
                  navigate(-1);
                }}
                className='back-button '
              >
                <img src={back} alt='back' />
              </div>
              <div>
                <div className='match-heading'>
                  {location?.state?.item?.type}
                </div>
              </div>
              <div></div>
            </div>

            <Divider />
            <div className='match-list-container mt-5'>
              <div className='score-provier'>
                <div className='title-score-provider'>
                  {location?.state?.item?.round}
                </div>
                <div className={"space-between"}>
                  <div className='flex-row'>
                    <img
                      alt='team'
                      src={
                        teams?.filter(
                          (g) => g.id === location?.state?.item?.localteam_id
                        )?.[0]?.image_path
                      }
                    />
                    <div className='score ml-20'>
                      {
                        teams?.filter(
                          (g) => g.id === location?.state?.item?.localteam_id
                        )?.[0]?.code
                      }
                    </div>
                  </div>
                  <div className='score' style={{ color: "#333333" }}>
                    {location?.state?.localteamscore?.total || 0}/
                    {location?.state?.localteamscore?.wickets || 0} (
                    {location?.state?.localteamscore?.overs} over)
                  </div>
                  {/* {!isMobile && (
                    <div className='flex-row'>
                      <img alt='team' src={ball} />
                      <div className='f-12 ml-10'>Ball Left: 12</div>
                    </div>
                  )} */}
                </div>
                {/* {isMobile && (
                  <div className='flex-row justify-center'>
                    <img alt='team' src={ball} />
                    <div className='f-12 ml-10'>Ball Left: 12</div>
                  </div>
                )} */}
                <div className='space-between mt-1'>
                  <div className='flex-row'>
                    <img
                      alt='team'
                      src={
                        teams?.filter(
                          (g) => g.id === location?.state?.item?.visitorteam_id
                        )?.[0]?.image_path
                      }
                    />
                    <div className='score ml-20'>
                      {
                        teams?.filter(
                          (g) => g.id === location?.state?.item?.visitorteam_id
                        )?.[0]?.code
                      }
                    </div>
                  </div>
                  <div className='score' style={{ color: "#333333" }}>
                    {location?.state?.visitorteamscore?.total || 0}/
                    {location?.state?.visitorteamscore?.wickets || 0} (
                    {location?.state?.visitorteamscore?.overs} over)
                  </div>
                  {/* {!isMobile && (
                    <div className='ball-left center'>
                      <div className=''>22 runs in 11 balls</div>
                    </div>
                  )} */}
                </div>
                {/* {isMobile && (
                  <div className='flex-row justify-center'>
                    {" "}
                    <div className='ball-left center '>
                      <div className=''>22 runs in 11 balls</div>
                    </div>
                  </div>
                )} */}
              </div>
              <div className='score-provier mt-20'>
                <p>
                  {
                    teams?.filter(
                      (g) => g.id === location?.state?.item?.localteam_id
                    )?.[0]?.code
                  }
                </p>
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={localteamscorebatting}
                />
                {/* <Table columns={columns} pagination={false} dataSource={localteamscorebowling} /> */}
                <div className='mt-10' />
                <Table
                  columns={columns1}
                  pagination={false}
                  dataSource={localteamscorebowling}
                />

                <p className='mt-20'>
                  {
                    teams?.filter(
                      (g) => g.id === location?.state?.item?.visitorteam_id
                    )?.[0]?.code
                  }
                </p>
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={visitorteamscorebatting}
                />
                {/* <Table columns={columns} pagination={false} dataSource={localteamscorebowling} /> */}
                <div className='mt-10' />
                <Table
                  columns={columns1}
                  pagination={false}
                  dataSource={visitorteamscorebowling}
                />
              </div>
            </div>
          </Col>
          <Col span={isMobile ? 0 : 6}>
            <div className='detail-news-container mt-20 ml-20'>
              <div className='top-news-title'>Top News</div>
              {news
                ?.filter(
                  (g) =>
                    g.image !== "" &&
                    g.image !== undefined &&
                    g.image !== "undefined" &&
                    g.image !== null &&
                    !g?.image?.includes("undefined")
                )
                ?.slice(0, 5)
                ?.map((x) => {
                  return (
                    <>
                      <img alt='news' className='mt-10' src={x?.image} />
                      <div className='news-heading'>{x?.title}</div>
                      {/* <div className='news-time'>Jeetwin, 11 min ago</div> */}
                      <div className='devider mt-5 mb-5' />
                    </>
                  );
                })}
            </div>
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};
export default Details;
