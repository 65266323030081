import React, { useEffect, useState } from "react";
import { Col, Row, Menu, Tag } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../assets/back.png";
import jwt_decode from "jwt-decode";
import moment from 'moment';

import { AppLayout, PollResultCard } from "../../Components";
import { matchType } from "../../Constant";
import useViewport from "../../Hooks/useViewPort";
import axios from "axios";
import { SERVER_URL } from "../../utils/server";

const PollResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useViewport();
  const [polls, setPolls] = useState([]);

  const param = new URLSearchParams(location?.search);
  let user = "";
  try {
    var getuserid = jwt_decode(localStorage.getItem("authToken"));
    user = getuserid?.id;
  } catch (e) {}

  const getPolls = async () => {
    try {
      const data = await axios.get(SERVER_URL + `/polls?user=${user}`);
         const sortedArray = data?.data?.data?.sort(
        (a, b) =>
          moment(b.createdAt).format('YYYYMMDD') -
          moment(a.createdAt).format('YYYYMMDD'),
      );

      setPolls(sortedArray);
    } catch (e) {}
  };
  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      if (param?.get("match") === null) {
        navigate(`?match=all`);
      }
    } else {
      navigate("/");
    }
  }, [param]);
  useEffect(() => {
    getPolls();
  }, []);

  return (
    <AppLayout>
      <div className='container'>
        <Row gutter={16}>
          {isMobile ? (
            <Menu
              className='bg-black  color-yellow w-full'
              mode='horizontal'
              defaultSelectedKeys={[param?.get("match")]}
              onClick={(e) => {
                navigate(`?match=${e?.key}`);
              }}
              items={matchType?.slice(0, 3)?.map((x) => {
                return {
                  key: x.key,
                  label: x.title,
                };
              })}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div>
                <div
                  onClick={() => {
                    navigate("/");
                  }}
                  className='back-button '
                >
                  <img src={back} alt='back' />
                </div>
              </div>
              <div>
                <Row gutter={16}>
                  {matchType?.slice(0, 3)?.map((x, i) => {
                    return (
                      <Col
                        onClick={() => {
                          navigate(`?match=${x?.key}`);
                        }}
                        key={i?.toString()}
                        span={8}
                      >
                        <Tag
                          className={
                            param.get("match") === x?.key ? "active-tag" : "tag"
                          }
                        >
                          <span>{x?.title}</span>
                        </Tag>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div></div>
            </div>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div style={{ position: "relative" }}>
              <div style={{ height: "72.5vh", overflow: "scroll" }} gutter={16}>
                {polls
                  ?.filter((g) =>
                    param?.get("match")==="all"
                      ? true
                      : param?.get("match") === "cricket"
                      ? g.type === "cricket"
                      : g.type === "football"
                  )
                  ?.map((x) => {
                    return (
                      <PollResultCard
                        user={user}
                        isfootball={x?.type !== "cricket"}
                        item={x}
                      />
                    );
                  })}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};
export default PollResults;
