import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  Details,
  Home,
  News,
  NewsDetails,
  Fixtures,
  Terms,
  Privacy,
  PollResults,
} from "../Pages";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/fixtures"} element={<Fixtures />} />
        <Route path={"/news"} element={<News />} />
        <Route path={"/news/:id"} element={<NewsDetails />} />
        <Route path={"/home/:id"} element={<Details />} />
        <Route path={"/terms-and-conditions"} element={<Terms />} />
        <Route path={"/privacy-policy"} element={<Privacy />} />
        <Route path={"/poll-results"} element={<PollResults />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
