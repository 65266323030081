import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Tabs,
  Skeleton,
  Divider,
  Avatar,
  Button,
  Modal,
  Input,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import notfound from "../../assets/notfound.png";
import copy from "../../assets/copy.png";
import jwt_decode from "jwt-decode";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { AppLayout, Matchcard } from "../../Components";
import { homeTab } from "../../Constant";
import useViewport from "../../Hooks/useViewPort";
import { NewsContext } from "../../Context/NewsProvider";
import { shuffle } from "../../utils/helper";
import circle from "../../assets/circl.png";
import next from "../../assets/next.png";
import help from "../../assets/help.png";
import previous from "../../assets/previous.png";
import referralpng from "../../assets/referral.png";
import close from "../../assets/close.png";

import {
  matchsetting,
  othernewssetting,
  topnewssetting,
} from "../../utils/slider";
import Loader from "../../Components/Loader";
import { toast } from "react-toastify";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useViewport();
  const sliderRef = useRef();

  const {
    livematch,
    recentmatch,
    upcominmatch,
    news,
    feed,
    footballnews,
    kabaddinews,
    newsloading,
    teams,
  } = useContext(NewsContext);
  const param = new URLSearchParams(location?.search);
  let data = {};
  try {
    data = jwt_decode(
      localStorage.getItem("authToken")
        ? localStorage.getItem("authToken")
        : null
    );
  } catch (e) {}

  const [showinvite, setShowinvite] = useState(false);

  const [currentTab, setCurrentTab] = useState("live");
  const onChange = (key) => {
    navigate(`?tab=${key}`);
  };
  const navigatenewsdetails = (x) => {
    navigate(`/news/details`, {
      state: {
        ...x,
        type: x?.link?.includes("sky24")
          ? "sky24"
          : x?.link?.includes("express")
          ? "express"
          : x?.link?.includes("cricbuzz")
          ? "cricbuzz"
          : x?.link?.includes("prokabaddi")
          ? "kabaddi"
          : x?.link?.includes("goal")
          ? "football"
          : "",
      },
    });
  };

  useEffect(() => {
    if (param?.get("tab") === null) {
      navigate(`/?tab=live`);
    }
    // eslint-disable-next-line
  }, [param]);

  useEffect(() => {
    if (upcominmatch?.length > 0 && livematch?.length === 0) {
      navigate(`?tab=upcoming`);
    } else {
      navigate(`?tab=live`);
    }
  }, [livematch]);

  useEffect(() => {
    setCurrentTab(param?.get("tab") ? param?.get("tab") : "live");
  }, [location, param]);

  const allnews =
    param?.get("match") === "all"
      ? shuffle(news?.concat(feed)?.concat(footballnews)?.concat(kabaddinews))
      : param?.get("match") === "football"
      ? footballnews
      : param?.get("match") === "kabaddi"
      ? kabaddinews
      : news?.concat(feed);

  return (
    <AppLayout>
      <Modal
        open={showinvite}
        centered
        mask={true}
        closable={false}
        footer={""}
      >
        <div
          className='bg-white '
          style={{
            borderRadius: 10,
            position: "relative",
            width: isMobile ? "100%" : window.innerWidth / 2,
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 9999,
              bottom: isMobile ? -50 : "unset",
              right: isMobile ? "45%" : -50,
              cursor: "pointer",
            }}
            onClick={() => {
              setShowinvite(!showinvite);
            }}
          >
            <img src={close} alt='close' />
          </div>

          <div
            className='modal-login-vertical-center'
            style={{
              padding: isMobile ? 15 : 20,
              width: isMobile ? "100%" : "95%",
            }}
          >
            <div
              style={{ fontSize: 20, fontWeight: "bold" }}
              className='center'
            >
              Invite & Win Jeet Coin
            </div>
            <p className='center' style={{ textAlign: "center" }}>
              Both you and your friend will get 5 Jeet Coins when each friend
              logged in with your referral code
            </p>
          </div>
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            <Input
              size='small'
              disabled
              value={data?.referral}
              suffix={
                <CopyToClipboard
                  text={` Hey, join me on the Jeetwin app. Download the app using the link https://play.google.com/store/apps/details?id=com.jeetwin And use my referral code  ${data?.referral} to earn 100 Jeetcoins. 😍 `}
                  onCopy={() =>
                    toast.success("Referral code copied to clipboard")
                  }
                >
                  <img
                    src={copy}
                    style={{ marginRight: -7, cursor: "pointer" }}
                  />
                </CopyToClipboard>
              }
            />
          </div>
        </div>
      </Modal>

      {/* <Loader isOpen={upcominmatch?.length === 0} /> */}
      <div className='container'>
        <Row gutter={16}>
          <div className='home-heading'>Trending matches</div>
          <Col span={24}>
            <Tabs
              defaultActiveKey={param?.get("tab")}
              activeKey={param?.get("tab")}
              items={homeTab}
              type='primary'
              onChange={onChange}
            />

            <div style={{ position: "relative" }}>
              {livematch?.length === 0 && currentTab === "live" && (
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ width: isMobile ? "100%" : window.innerWidth / 2 }}
                    className='match-card mt-20 cursor-pointer center'
                  >
                    <div style={{ textAlign: "center" }}>
                      <Avatar
                        style={{ textAlign: "center" }}
                        size={200}
                        src={notfound}
                      />
                      <div>
                        <div
                          style={{ textAlign: "center", marginTop: 20 }}
                          className='not-found-title'
                        >
                          No live matches there !
                        </div>
                        <div
                          style={{ textAlign: "center", marginTop: 20 }}
                          className='not-found-text'
                        >
                          There is no live matches currently. Check upcoming
                          matches
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {currentTab === "live" && (
                <Slider ref={sliderRef} {...matchsetting}>
                  {livematch
                    ?.filter((g) =>
                      g?.type === "football"
                        ? true
                        : g?.scoreboards?.length > 0 &&
                          teams?.filter(
                            (x) =>
                              x.type === "cricket" && x.id === g?.localteam_id
                          )?.[0]?.name !== undefined
                    )
                    ?.map((x) => {
                      return (
                        x !== null && (
                          <Matchcard
                            isfootball={x?.type === "football"}
                            item={x}
                            isLive={true}
                            shoPrediction={true}
                          />
                        )
                      );
                    })}
                </Slider>
              )}

              {currentTab === "upcoming" && (
                <Slider ref={sliderRef} {...matchsetting}>
                  {upcominmatch?.map((x) => {
                    return (
                      <Matchcard
                        navigatable={false}
                        isLive={false}
                        item={x?.data}
                        isUpcoming={true}
                        isfootball={x?.type === "football"}
                        shoPrediction={true}
                      />
                    );
                  })}
                </Slider>
              )}

              {currentTab === "recent" && (
                <Slider ref={sliderRef} {...matchsetting}>
                  {recentmatch?.map((x) => {
                    return (
                      <Matchcard
                        isfootball={x?.type === "football"}
                        isLive={false}
                        item={x?.data}
                        shoPrediction={false}
                        isRecent={true}
                      />
                    );
                  })}
                </Slider>
              )}

              {!isMobile &&
                (livematch?.length > 3 || param?.get("tab") !== "live") && (
                  <div style={{ position: "absolute", right: 0, bottom: -70 }}>
                    <img
                      onClick={() => {
                        sliderRef.current?.slickPrev();
                      }}
                      src={previous}
                      className='cursor-pointer'
                    />
                    <img
                      onClick={() => {
                        sliderRef.current?.slickNext();
                      }}
                      src={next}
                      className='cursor-pointer'
                    />
                  </div>
                )}
            </div>
            <Row className='mt-50' gutter={16}>
              <Col span={isMobile ? 24 : 12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: 20,
                  }}
                  className='offer-card '
                >
                  <div style={{ position: "absolute", left: 0 }}>
                    <img src={circle} />
                  </div>
                  <div style={{ position: "absolute", right: 25, top: "25%" }}>
                    <img src={help} />
                  </div>
                  <div className='offer-card-title'>Participate in Polls</div>
                  <div className='offer-card-title'>and win Jeet coins</div>
                  <div className='tnc-apply-txt'>*Terms & conditions apply</div>
                </div>
              </Col>
              <Col
                className={isMobile ? "mt-20" : ""}
                span={isMobile ? 24 : 12}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: 20,
                  }}
                  className='invite-card '
                >
                  <div style={{ position: "absolute", left: 0 }}>
                    <img src={circle} />
                  </div>
                  <div style={{ position: "absolute", right: 25, top: "25%" }}>
                    <img src={referralpng} />
                  </div>
                  <div className='offer-card-title'>Invite and win</div>
                  <div className='offer-card-title'>Jeet Coins</div>
                  <Button
                    style={{
                      width: isMobile ? 150 : 250,
                      background: "#F8BB22",
                      color: "black",
                      fontWeight: "bold",
                      marginTop: 10,
                    }}
                    size='middle'
                    type='primary'
                    shape='default'
                    onClick={() => {
                      if (localStorage.getItem("authToken")) {
                        setShowinvite(!showinvite);
                      } else {
                        toast.error("Please login");
                      }
                    }}
                  >
                    Invite Now
                  </Button>
                  <div className='tnc-apply-txt'>*Terms & conditions apply</div>
                </div>
              </Col>
            </Row>

            {news?.length > 0 ? (
              <Row gutter={16} className='mt-50'>
                <Col span={isMobile ? 24 : 18}>
                  <div className='top-news-title'>Top News</div>
                  {newsloading ? (
                    <>
                      <Skeleton.Image
                        active={true}
                        className='mt-10 home-news'
                      />
                      <Skeleton />
                    </>
                  ) : (
                    <Slider className='top-news' {...topnewssetting}>
                      {news?.length > 0 &&
                        news
                          ?.filter(
                            (g) =>
                              g.image !== "" &&
                              g.image !== undefined &&
                              g.image !== "undefined" &&
                              g.image !== null &&
                              !g?.image?.includes("undefined")
                          )
                          ?.slice(8, 12)
                          ?.map((x) => {
                            return (
                              <div
                                className='cursor-pointer'
                                onClick={() => {
                                  navigatenewsdetails(x);
                                }}
                              >
                                <img
                                  src={
                                    x?.image?.includes("cricbuzz")
                                      ? x?.image?.replace("100x77", "700x400")
                                      : x?.image?.includes("goal")
                                      ? x?.image?.replace(
                                          "quality=60",
                                          "quality=100"
                                        )
                                      : x?.image?.includes("w=")
                                      ? x?.image?.replace("w=400", "w=700")
                                      : x?.image?.includes("resize")
                                      ? x?.image?.replace(
                                          "?resize=270,152",
                                          "?resize=800,400"
                                        )
                                      : x?.image
                                  }
                                  className='main-news-image'
                                  alt='img'
                                />
                                <div className='main-news-title one-ellips mt-20'>
                                  {x?.title}
                                </div>
                              </div>
                            );
                          })}
                    </Slider>
                  )}

                  <div className='top-news-title mb-20 mt-20'>Cricket</div>
                  {newsloading ? (
                    <>
                      <Skeleton.Image
                        active={true}
                        className='mt-10 home-news'
                      />
                      <Skeleton />
                    </>
                  ) : (
                    <Slider
                      className='top-news slider-new-single'
                      {...othernewssetting}
                    >
                      {news?.length > 0 &&
                        news
                          ?.concat(feed)
                          ?.filter(
                            (g) =>
                              g.image !== "" &&
                              g.image !== undefined &&
                              g.image !== "undefined" &&
                              g.image !== null &&
                              !g?.image?.includes("undefined")
                          )
                          ?.slice(0, 6)
                          ?.map((x) => {
                            return (
                              <div
                                className='cursor-pointer'
                                onClick={() => {
                                  navigatenewsdetails(x);
                                }}
                              >
                                <img
                                  src={x?.image}
                                  className='small-news-card-image'
                                  alt='img'
                                  style={{
                                    width: "100%",
                                    borderRadius: 10,
                                    objectFit: "cover",
                                  }}
                                />
                                <div className='news-single-title cursor-pointer  three-dot'>
                                  {x?.title}
                                </div>
                              </div>
                            );
                          })}
                    </Slider>
                  )}
                  <div className='devider mt-10 mb-10' />
                  <div className='top-news-title mb-20 mt-20'>Football</div>
                  {newsloading ? (
                    <>
                      <Skeleton.Image
                        active={true}
                        className='mt-10 home-news'
                      />
                      <Skeleton />
                    </>
                  ) : (
                    <Slider
                      className='top-news slider-new-single'
                      {...othernewssetting}
                    >
                      {footballnews?.length > 0 &&
                        footballnews
                          ?.filter(
                            (g) =>
                              g.image !== "" &&
                              g.image !== undefined &&
                              g.image !== "undefined" &&
                              g.image !== null &&
                              !g?.image?.includes("undefined")
                          )
                          ?.slice(0, 6)
                          ?.map((x) => {
                            return (
                              <div
                                className='cursor-pointer'
                                onClick={() => {
                                  navigatenewsdetails(x);
                                }}
                              >
                                <img
                                  src={x?.image}
                                  className='small-news-card-image'
                                  alt='img'
                                  style={{
                                    width: "100%",
                                    borderRadius: 10,
                                    objectFit: "cover",
                                  }}
                                />
                                <div className='news-single-title cursor-pointer  three-dot'>
                                  {x?.title}
                                </div>
                              </div>
                            );
                          })}
                    </Slider>
                  )}
                  <div className='devider mt-10 mb-10' />
                  <div className='top-news-title mb-20 mt-20'>Kabbadi</div>
                  {newsloading ? (
                    <>
                      <Skeleton.Image
                        active={true}
                        className='mt-10 home-news'
                      />
                      <Skeleton />
                    </>
                  ) : (
                    <Slider
                      className='top-news slider-new-single'
                      {...othernewssetting}
                    >
                      {kabaddinews?.length > 0 &&
                        kabaddinews
                          ?.filter(
                            (g) =>
                              g.image !== "" &&
                              g.image !== undefined &&
                              g.image !== "undefined" &&
                              g.image !== null &&
                              !g?.image?.includes("undefined")
                          )
                          ?.slice(0, 6)
                          ?.map((x) => {
                            return (
                              <div
                                className='cursor-pointer'
                                onClick={() => {
                                  navigatenewsdetails(x);
                                }}
                              >
                                <img
                                  src={x?.image}
                                  className='small-news-card-image'
                                  alt='img'
                                  style={{
                                    width: "100%",
                                    borderRadius: 10,
                                    objectFit: "cover",
                                  }}
                                />
                                <div className='news-single-title cursor-pointer   three-dot'>
                                  {x?.title}
                                </div>
                              </div>
                            );
                          })}
                    </Slider>
                  )}
                  <div className='devider mt-10 mb-10' />
                </Col>
                <Col span={isMobile ? 0 : 6}>
                  <div className='detail-news-container1 ml-20'>
                    <div className='top-news-title'>Latest News</div>
                    {newsloading
                      ? [12, 4, 5, 6, 7].map((ff) => {
                          return (
                            <>
                              <Skeleton.Image
                                active={true}
                                className='mt-10 home-news'
                              />
                              <Skeleton />
                            </>
                          );
                        })
                      : allnews
                          ?.filter(
                            (g) =>
                              g.image !== "" &&
                              g.image !== undefined &&
                              g.image !== "undefined" &&
                              g.image !== null &&
                              !g?.image?.includes("undefined")
                          )
                          ?.slice(0, 7)
                          ?.map((x) => {
                            return (
                              x?.description !== null && (
                                <>
                                  <img
                                    onClick={() => {
                                      navigate(`/news/details`, {
                                        state: {
                                          ...x,
                                          type: x?.link?.includes("sky24")
                                            ? "sky24"
                                            : x?.link?.includes("express")
                                            ? "express"
                                            : x?.link?.includes("cricbuzz")
                                            ? "cricbuzz"
                                            : x?.link?.includes("prokabaddi")
                                            ? "kabaddi"
                                            : x?.link?.includes("goal")
                                            ? "football"
                                            : "",
                                        },
                                      });
                                    }}
                                    alt='team'
                                    className='news-image-container cursor-pointer'
                                    src={x?.image}
                                    style={{
                                      width: "100%",
                                      borderRadius: 10,
                                      objectFit: "cover",
                                    }}
                                  />
                                  <div
                                    onClick={() => {
                                      navigate(`/news/details`, {
                                        state: {
                                          ...x,
                                          type: x?.link?.includes("sky24")
                                            ? "sky24"
                                            : x?.link?.includes("express")
                                            ? "express"
                                            : x?.link?.includes("cricbuzz")
                                            ? "cricbuzz"
                                            : x?.link?.includes("prokabaddi")
                                            ? "kabaddi"
                                            : x?.link?.includes("goal")
                                            ? "football"
                                            : "",
                                        },
                                      });
                                    }}
                                    className='news-single-title cursor-pointer mt-10 mb-10 two-ellips1'
                                  >
                                    {x?.title}
                                  </div>

                                  <div className='devider mt-10 mb-10' />
                                </>
                              )
                            );
                          })}
                  </div>
                </Col>
              </Row>
            ) : (
              <Row gutter={16} className='mt-50'>
                <Col span={isMobile ? 24 : 18}>
                  <div className='top-news-title'>Top News</div>

                  <Skeleton.Image active={true} className='mt-10 home-news' />
                  <Skeleton />
                </Col>
                <Col span={isMobile ? 24 : 18}>
                  <Skeleton.Image active={true} className='mt-10 home-news' />
                  <Skeleton />
                </Col>
                <Col span={isMobile ? 24 : 18}>
                  <Skeleton.Image active={true} className='mt-10 home-news' />
                  <Skeleton />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};
export default Home;
