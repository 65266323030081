import React, { useContext } from "react";
import coin from "../../assets/coin.png";
import hand from "../../assets/hand.png";
import { NewsContext } from "../../Context/NewsProvider";
import moment from 'moment'

const PollResultCard = ({ isfootball, item, user }) => {
  const { votes, teams } = useContext(NewsContext);

  const overallrate = votes?.filter((x) => x?.id == item?.id);

  const filterVotes = votes?.filter(
    (x) => x?.id == item?.id && x?.user === user
  );

  const firtTeam = isfootball
    ? overallrate?.filter(
        (x) =>
          x.team ==
          item?.data?.name?.substring(0, item?.data?.name?.indexOf("vs"))?.trim()
      )
    : overallrate?.filter((x) => x.team == item?.data?.localteam_id);
  const secondTeam = isfootball
    ? overallrate?.filter(
        (x) =>
          x.team ==
          item?.data?.name
            .substring(item?.data?.name?.indexOf("vs"), item?.data?.name?.length)
            ?.replace("vs", "")?.trim()
      )
    : overallrate?.filter((x) => x.team == `${item?.data?.visitorteam_id}`);

  const drawTeam = overallrate?.filter((x) => x.team === "draw");

  const localteamPercentage = Math.round(
    (firtTeam?.length / overallrate?.length) * 100
  );

  const visitorteamPercentage = Math.round(
    (secondTeam?.length / overallrate?.length) * 100
  );

  const drawteamPercentage = Math.round(
    (drawTeam?.length / overallrate?.length) * 100
  );

  return (
    <div className='box-poll' style={{ marginTop: 20, padding: 30 }}>
      <div className='prediction-row ' style={{ padding: 0, marginBottom: 10 }}>
        <div style={{ fontSize: 16 }} className='success'>
          {isfootball
            ? item?.data?.name?.substring(0,  item?.data?.name?.indexOf("vs"))?.trim()
              ?  item?.data?.name?.substring(0,  item?.data?.name?.indexOf("vs"))?.trim()
              : "-"
            : teams?.filter(
                (g) => g.type === "cricket" && g.id == item?.data?.localteam_id
              )?.[0]?.name
            ? teams?.filter(
                (g) => g.type === "cricket" && g.id == item?.data?.localteam_id
              )?.[0]?.name
            : "--"}
          ({localteamPercentage}%)
          {isfootball &&
            filterVotes?.[0]?.team ==
            item?.data?.name?.substring(0, item?.data?.name?.indexOf("vs"))?.trim() && (
              <img src={hand} />
            )}
          {!isfootball &&
            `${filterVotes?.[0]?.team}` == `${item?.data?.localteam_id}` && (
              <img src={hand} />
            )}
        </div>
        <div style={{ fontSize: 16 }} className='draw'>
          DRAW MATCH({drawteamPercentage}%){" "}
          {filterVotes?.[0]?.team === "draw" && <img src={hand} />}
        </div>
        <div style={{ fontSize: 16 }} className='low'>
          {isfootball
            ? item?.data?.name
                .substring(item?.data?.name?.indexOf("vs"), item?.data?.name?.length)
                ?.replace("vs", "")?.trim()
              ? item?.data?.name
                  .substring(
                    item?.data?.name?.indexOf("vs"),
                    item?.data?.name?.length
                  )
                  ?.replace("vs", "")?.trim()
              : "-"
            : teams?.filter(
                (g) =>
                  g.type === "cricket" && g.id == item?.data?.visitorteam_id
              )?.[0]?.name}
          ({visitorteamPercentage}%)
          {isfootball &&
            filterVotes?.[0]?.team ===
              item?.data?.name.substring(
                item?.data?.name.indexOf("vs"),
                item?.data?.name?.length
              ) && <img src={hand} />}
          {!isfootball &&
            `${filterVotes?.[0]?.team}` == `${item?.data?.visitorteam_id}` && (
              <img src={hand} />
            )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            display: "flex",
            width: "100%",
            padding: 0,
          }}
        >
          <div
            style={{
              position: "relative",
              width: `${localteamPercentage}%`,
              backgroundColor: "green",
              height: 10,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              padding: 10,
            }}
          ></div>

          <div
            style={{
              position: "relative",
              width: `${drawteamPercentage}%`,
              backgroundColor: "gray",

              height: 10,
              padding: 10,
            }}
          ></div>

          <div
            style={{
              position: "relative",
              width: `${visitorteamPercentage}%`,
              backgroundColor: "red",
              height: 10,
              padding: 10,
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}
          ></div>
        </div>
      </div>
      <div
        className='prediction-row '
        style={{ padding: 0, marginTop: 10, justifyContent: "space-between" }}
      >
        <div className='pol-date'>     {moment(item?.data?.starting_at)?.format("DD MMM YYYY")}</div>
        <div
          style={{ color: filterVotes?.[0]?.isWinner ? "#1F9138" : "#103C8C" }}
          className='pol-result'
        >
          {filterVotes?.[0]?.isFinish===false?"Match ongoing":  filterVotes?.[0]?.isWinner
            ? "You won the poll"
            : "You Loss the poll"}
        </div>
      </div>
      <div className='fixture-note'>
        <div className='note-text'> {item?.data?.note||item?.data?.result_info}</div>
        {filterVotes?.[0]?.isWinner && (
          <div
            style={{
              fontSize: 14,
              fontWeight: "600",
              color: "black",
              marginRight: 10,
            }}
          >
            <img src={coin} /> 100 Jeet coins
          </div>
        )}
      </div>
    </div>
  );
};

export default PollResultCard;
