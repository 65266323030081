import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import { NewsContext } from "../../Context/NewsProvider";
import moment from "moment";
import { capitalizeFirstLetter } from "../../utils/helper";
import axios from "axios";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import { SERVER_URL } from "../../utils/server";
import { toast } from "react-toastify";
const Matchcard = ({
  isUpcoming,
  item,
  isLive,
  navigatable,
  isfootball,
  onlycol,
  isRecent,
  shoPrediction,
}) => {
  const navigate = useNavigate();
  const { teams, venues, getVotes, votes } = useContext(NewsContext);

  const localteamscore = item?.scoreboards
    ?.filter((g) => g.type === "total")
    ?.filter((x) => x.team_id === item?.localteam_id)?.[0];
  const visitorteamscore = item?.scoreboards
    ?.filter((g) => g.type === "total")
    ?.filter((x) => x.team_id === item?.visitorteam_id)?.[0];
  // console.log(item);
  const secondHalf = item?.scores?.filter((x) => x?.description === "2ND_HALF");
  // console.log(
  //   venues?.filter((x) =>
  //    x?.type==="cricket"&&x?.data?.id===item?.venue_id
  //   )?.[0]?.data?.name
  // );
  let user = "";
  try {
    var getuserid = jwt_decode(localStorage.getItem("authToken"));
    user = getuserid?.id;
  } catch (e) {}
  const onVotes = async (id, type, team) => {
    var decoded = jwt_decode(localStorage.getItem("authToken"));
    try {
      const add = await axios.get(
        `${SERVER_URL}/add/votes?id=${id}&user=${decoded?.id}&type=${type}&team=${team}`
      );
      if (add?.data?.data?.error) {
        toast.error(add?.data?.data?.message);
      } else {
        toast.success(add?.data?.data?.message);
        await getVotes();
      }
    } catch (e) {}
  };

  const overallrate = votes?.filter((x) => x?.id == item?.id);

  const filterVotes = votes?.filter(
    (x) => x?.id == item?.id && x.user === user
  );

  const firtTeam = isfootball
    ? overallrate?.filter(
        (x) => x.team == item?.name?.substring(0, item?.name?.indexOf("vs"))?.trim()
      )
    : overallrate?.filter((x) => x.team == item?.localteam_id);
  const secondTeam = isfootball
    ? overallrate?.filter(
        (x) =>
          x.team ==
          item?.name
            .substring(item?.name.indexOf("vs"), item?.name?.length)
            ?.replace("vs", "")?.trim()
      )
    : overallrate?.filter((x) => x.team == `${item?.visitorteam_id}`);

  const drawTeam = overallrate?.filter((x) => x.team === "draw");

  const localteamPercentage = Math.round(
    (firtTeam?.length / overallrate?.length) * 100
  );

  const visitorteamPercentage = Math.round(
    (secondTeam?.length / overallrate?.length) * 100
  );

  const drawteamPercentage = Math.round(
    (drawTeam?.length / overallrate?.length) * 100
  );


  return (
    <div
    
      className='match-card mt-20 '
    >
      <div   onClick={() => {
        if (navigatable !== false && !isfootball) {
          navigate("/home/score", {
            state: {
              item,
              localteamscore,
              visitorteamscore,
            },
          });
        }
      }} className="cursor-pointer">
        
      <div className='space-between'>
        <div className='match-title'>
          {isfootball
            ? capitalizeFirstLetter(item?.name)
            : capitalizeFirstLetter(item?.type)}
        </div>

        {isLive !== false && (
          <div className='space-between'>
            <div className='live-dot' />
            <div className='live'>Live</div>
          </div>
        )}
      </div>
      {/* {isLive === false && ( */}
      <div
        className={onlycol ? "" : "ml-10"}
        style={{ marginTop: onlycol ? 0 : -20 }}
      >
        <div className='start-time'>
          {moment(item?.starting_at)?.format("DD MMM YYYY")}
        </div>
      </div>
      {/* )} */}
      <div className='space-around mt-20'>
        <div className='center-container'>
          <img />
          <Avatar
            alt='team'
            style={{
              height: 54,
              width: 54,
              objectFit: "contain",
            }}
            src={
              isfootball
                ? teams?.filter(
                    (g) =>
                      g?.type === "football" &&
                      g.id === item?.participants?.[0]?.id
                  )?.[0]?.image_path
                : teams?.filter(
                    (g) => g?.type === "cricket" && g.id === item?.localteam_id
                  )?.[0]?.image_path
            }
          />
          <div className='team'>
            {isfootball
              ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                : "-"
              : teams?.filter(
                  (g) => g.type === "cricket" && g.id === item?.localteam_id
                )?.[0]?.name
              ? teams?.filter(
                  (g) => g.type === "cricket" && g.id === item?.localteam_id
                )?.[0]?.name
              : "--"}
          </div>
          {isfootball
            ? navigatable !== false && (
                <>
                  <div className='score1'>
                    FT
                    <span className='over '>
                      {" "}
                      (
                      {secondHalf?.filter(
                        (x) => x.participant_id === item?.participants?.[0]?.id
                      )?.[0]?.score?.goals || 0}{" "}
                      goal )
                    </span>
                  </div>
                </>
              )
            : !isUpcoming && (
                <div className='score'>
                  {localteamscore?.total || 0}/{localteamscore?.wickets || 0}{" "}
                  <span className='over'>({localteamscore?.overs} Over)</span>
                </div>
              )}
        </div>

        <div className='vs'>
          <div className='vs-text'>VS</div>
        </div>
        <div className='center-container'>
          <Avatar
            alt='team'
            src={
              isfootball
                ? teams?.filter(
                    (g) =>
                      g?.type === "football" &&
                      g.id === item?.participants?.[1]?.id
                  )?.[0]?.image_path
                : teams?.filter(
                    (g) =>
                      g?.type === "cricket" && g.id === item?.visitorteam_id
                  )?.[0]?.image_path
            }
            style={{
              height: 54,
              width: 54,
              objectFit: "contain",
            }}
          />

          <div className='team'>
            {isfootball
              ? item?.name
                  .substring(item?.name.indexOf("vs"), item?.name?.length)
                  ?.replace("vs", "")
                ? item?.name
                    .substring(item?.name.indexOf("vs"), item?.name?.length)
                    ?.replace("vs", "")
                : "-"
              : teams?.filter(
                  (g) => g.type === "cricket" && g.id === item?.visitorteam_id
                )?.[0]?.name}
          </div>
          {!isUpcoming && isfootball
            ? navigatable !== false && (
                <>
                  <div className='score1'>
                    FT
                    <span className='over '>
                      {" "}
                      (
                      {secondHalf?.filter(
                        (x) => x.participant_id === item?.participants?.[1]?.id
                      )?.[0]?.score?.goals || 0}{" "}
                      goal )
                    </span>
                  </div>
                </>
              )
            : !isUpcoming && (
                <div className='score'>
                  {visitorteamscore?.total || 0}/
                  {visitorteamscore?.wickets || 0}{" "}
                  <span className='over'>({visitorteamscore?.overs} Over)</span>
                  {/* {item?.visitorteam_dl_data?.score || 0}/
            {item?.visitorteam_dl_data?.wickets_out || 0}{" "}
            <span className='over'>
              ({item?.visitorteam_dl_data?.overs || item?.total_overs_played}{" "}
              Over)
            </span> */}
                </div>
              )}
        </div>
      </div>
      {isUpcoming && (
        <div className='score'>
          {" "}
          {
            venues?.filter((x) => x?.data?.id === item?.venue_id)?.[0]?.data
              ?.name
          }
        </div>
      )}

</div>
      {localStorage.getItem("authToken") &&
        shoPrediction &&
        filterVotes?.length === 0 && (
          <div className='prediction-container mt-10'>
            <div className=''>Who will win?</div>
            <div className='prediction-row'>
              <div
                onClick={() => {
                  onVotes(
                    item?.id,
                    isfootball ? "football" : "cricket",
                    isfootball
                      ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                        ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                        : "-"
                      : item?.localteam_id
                  );
                }}
                className='prediction-button'
              >
                {isfootball
                  ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                    ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                    : "-"
                  : teams?.filter(
                      (g) => g.type === "cricket" && g.id === item?.localteam_id
                    )?.[0]?.name}
              </div>
              <div
                onClick={() => {
                  onVotes(
                    item?.id,
                    isfootball ? "football" : "cricket",
                    isfootball
                      ? item?.name
                          .substring(
                            item?.name.indexOf("vs"),
                            item?.name?.length
                          )
                          ?.replace("vs", "")
                        ? item?.name
                            .substring(
                              item?.name.indexOf("vs"),
                              item?.name?.length
                            )
                            ?.replace("vs", "")
                        : "-"
                      : item?.visitorteam_id
                  );
                }}
                className='prediction-button'
              >
                {isfootball
                  ? item?.name
                      .substring(item?.name.indexOf("vs"), item?.name?.length)
                      ?.replace("vs", "")
                    ? item?.name
                        .substring(item?.name.indexOf("vs"), item?.name?.length)
                        ?.replace("vs", "")
                    : "-"
                  : teams?.filter(
                      (g) =>
                        g.type === "cricket" && g.id === item?.visitorteam_id
                    )?.[0]?.name}
              </div>
              <div
                onClick={() => {
                  onVotes(
                    item?.id,
                    isfootball ? "football" : "cricket",
                    "draw"
                  );
                }}
                className='prediction-button'
              >
                Draw
              </div>
            </div>
          </div>
        )}
      {filterVotes?.length > 0 && (
        <div className='prediction-container'>
             <div className='prediction-row ' style={{ padding: 5 }}>
            <div className='success'>
              {isfootball
                ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                  ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                  : "-"
                : teams?.filter(
                    (g) => g.type === "cricket" && g.id === item?.localteam_id
                  )?.[0]?.name
                ? teams?.filter(
                    (g) => g.type === "cricket" && g.id === item?.localteam_id
                  )?.[0]?.name
                : "--"}
              ({localteamPercentage}%)
            </div>
            <div className='draw'>DRAW MATCH({drawteamPercentage}%)</div>
            <div className='low'>
              {isfootball
                ? item?.name
                    .substring(item?.name.indexOf("vs"), item?.name?.length)
                    ?.replace("vs", "")
                  ? item?.name
                      .substring(item?.name.indexOf("vs"), item?.name?.length)
                      ?.replace("vs", "")
                  : "-"
                : teams?.filter(
                    (g) => g.type === "cricket" && g.id === item?.visitorteam_id
                  )?.[0]?.name}
              ({visitorteamPercentage}%)
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                display: "flex",
                width: "100%",
                padding: 0,
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: `${localteamPercentage}%`,
                  backgroundColor: "green",
                  height: 10,
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                }}
              ></div>

              <div
                style={{
                  position: "relative",
                  width: `${drawteamPercentage}%`,
                  backgroundColor: "gray",

                  height: 10,
                  padding: 10,
                }}
              ></div>

              <div
                style={{
                  position: "relative",
                  width: `${visitorteamPercentage}%`,
                  backgroundColor: "red",
                  height: 10,
                  padding: 10,
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Matchcard;
