import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Divider, Skeleton } from "antd";
import back from "../../assets/back.png";
import { AppLayout } from "../../Components";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import { SERVER_URL } from "../../utils/server";
import { applyRules, shuffle } from "../../utils/helper";
import "./style.css";
import useViewport from "../../Hooks/useViewPort";
import { NewsContext } from "../../Context/NewsProvider";

const NewsDetails = () => {
  const navigate = useNavigate();
  const [newsdetails, setNewsdetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useViewport();
  const location = useLocation();
  const { news, feed, footballnews, kabaddinews, newsloading } =
    useContext(NewsContext);

  const getnewsdetails = async () => {
    setIsLoading(true);
    try {
      const data = await axios.get(
        SERVER_URL + `/news/details?url=${location?.state?.link}`
      );
      setIsLoading(false);
      setNewsdetails(data?.data?.data);
    } catch (e) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getnewsdetails();
  }, [location]);
  return (
    <AppLayout>
      <div className='container'>
        <Breadcrumb
          separator='>>'
          items={[
            {
              title: "News",
              className: "cursor-pointer",
              onClick: () => {
                navigate(-1);
              },
            },
            {
              title:
                location?.state?.type === "express"
                  ? "Cricket"
                  : location?.state?.type === "sky24"
                  ? "Cricket"
                  : location?.state?.type === "cricbuzz"
                  ? "Cricket"
                  : location?.state?.type,
            },
          ]}
        />
        <Row gutter={16}>
          <Col span={isMobile ? 24 : 18}>
            <div style={{ marginTop: 10 }} className='space-between'>
              {/* <div
                onClick={() => {
                  navigate(-1);
                }}
                className='back-button '
              >
                <img src={back} alt='back' />
              </div> */}
              <div>
                <div style={{ fontSize: 16 }} className='heading'>
                  {location?.state?.title}
                </div>
              </div>
              <div></div>
            </div>

            <Divider />
            <div className='match-list-container mt-5 '>
              <img
                src={
                  location?.state?.image?.includes("cricbuzz")
                  ? location?.state?.image?.replace("100x77", "700x400"):
                  location?.state?.image?.includes("goal")
                    ? location?.state?.image?.replace(
                        "quality=60",
                        "quality=100"
                      )
                    : location?.state?.image?.includes("w=")
                    ? location?.state?.image?.replace("w=400", "w=700")
                    : location?.state?.image?.includes("resize")
                    ? location?.state?.image?.replace(
                        "?resize=270,152",
                        "?resize=800,400"
                      )
                    : location?.state?.image
                }
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
              <p
                className='article-description'
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                {location?.state?.description}
              </p>
              {isLoading ? (
                [1, 2, 3, 4].map((g) => {
                  return <Skeleton />;
                })
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${applyRules(
                        newsdetails?.football?.[0]?.title
                      )}`,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${applyRules(
                        newsdetails?.prokabaddi?.[0]?.title
                      )}`,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${applyRules(newsdetails?.express?.[0]?.title)}`,
                    }}
                  />
                  {newsdetails?.sky24?.[0]?.title?.length > 0 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${applyRules(newsdetails?.sky24?.[0]?.title)}`,
                      }}
                    />
                  ) : (
                    newsdetails?.cricbuzz?.map((g) => {
                      return (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${applyRules(g?.title)}`,
                          }}
                        />
                      );
                    })
                  )}
                </>
              )}
              <div className='mt-10 mb-10' />
              <div className='mt-10 mb-10' />
              <b
                onClick={() => {
                  window.open(location?.state?.link, "_blank");
                }}
                style={{ fontSize: 15, cursor: "pointer", marginTop: 20 }}
              >
                Source :{" "}
                {location?.state?.link?.includes("sky24")
                  ? "sky247"
                  : location?.state?.link?.includes("express")
                  ? "Indianexpress"
                  : location?.state?.link?.includes("cricbuzz")
                  ? "cricbuzz"
                  : location?.state?.link?.includes("prokabaddi")
                  ? "Prokabaddi"
                  : location?.state?.link?.includes("goal")
                  ? "Goal"
                  : ""}
              </b>
            </div>
          </Col>

          <Col span={isMobile ? 0 : 6}>
            <div className='detail-news-container1 mt-20 ml-20'>
              <div className='top-news-title'>Latest News</div>
              {newsloading
                ? [12, 4, 5, 6, 7].map((ff) => {
                    return (
                      <>
                        <Skeleton.Image
                          active={true}
                          className='mt-10 home-news'
                        />
                        <Skeleton />
                      </>
                    );
                  })
                : shuffle(
                    news
                      ?.concat(feed)
                      ?.concat(footballnews)
                      ?.concat(kabaddinews)
                  )
                    ?.filter(
                      (g) =>
                      g.image !== "" &&
                      g.image !== undefined &&
                      g.image !== "undefined" &&
                      g.image !== null &&
                      !g?.image?.includes("undefined")
                    )
                    ?.slice(0, 8)
                    ?.map((x) => {
                      return (
                        x?.description !== null && (
                          <>
                            <img
                              alt='team'
                              className='news-image-container'
                              src={x?.image}
                              style={{
                                width: "100%",
                                borderRadius: 10,
                                objectFit: "cover",
                                cursor:"pointer"
                              }}
                              onClick={() => {
                                navigate(`/news/details`, {
                                  state: {
                                    ...x,
                                    type: x?.link?.includes("sky24")
                                      ? "sky24"
                                      : x?.link?.includes("express")
                                      ? "express"
                                      : x?.link?.includes("cricbuzz")
                                      ? "cricbuzz"
                                      : x?.link?.includes("prokabaddi")
                                      ? "kabaddi"
                                      : x?.link?.includes("goal")
                                      ? "football"
                                      : "",
                                  },
                                });
                              }}
                            />
                            <div
                              onClick={() => {
                                navigate(`/news/details`, {
                                  state: {
                                    ...x,
                                    type: x?.link?.includes("sky24")
                                      ? "sky24"
                                      : x?.link?.includes("express")
                                      ? "express"
                                      : x?.link?.includes("cricbuzz")
                                      ? "cricbuzz"
                                      : x?.link?.includes("prokabaddi")
                                      ? "kabaddi"
                                      : x?.link?.includes("goal")
                                      ? "football"
                                      : "",
                                  },
                                });
                              }}
                              className='news-single-title cursor-pointer mt-10 mb-10 two-ellips1'
                            >
                              {x?.title}
                            </div>
                            <div className='devider mt-10 mb-10' />
                          </>
                        )
                      );
                    })}
            </div>
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};
export default NewsDetails;
