import React, { useContext } from "react";
import "./style.css";
import useViewport from "../../Hooks/useViewPort";
import { NewsContext } from "../../Context/NewsProvider";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { capitalizeFirstLetter } from "../../utils/helper";
import { Avatar } from "antd";
import jwt_decode from "jwt-decode";
import coin from "../../assets/coin.png";
import hand from "../../assets/hand.png";
import { SERVER_URL } from "../../utils/server";
import { toast } from "react-toastify";
import axios from "axios";
const MatchTableCard = ({
  isUpcoming,
  item,
  isLive,
  navigatable,
  isfootball,
  showPrediction,
  onlycol,
}) => {
  const { isMobile } = useViewport();
  const navigate = useNavigate();
  const { teams, venues, getVotes, votes } = useContext(NewsContext);

  const localteamscore = item?.scoreboards
    ?.filter((g) => g.type === "total")
    ?.filter((x) => x.team_id === item?.localteam_id)?.[0];
  const visitorteamscore = item?.scoreboards
    ?.filter((g) => g.type === "total")
    ?.filter((x) => x.team_id === item?.visitorteam_id)?.[0];
  // console.log(item);
  const secondHalf = item?.scores?.filter((x) => x?.description === "2ND_HALF");

  let user = "";
  try {
    var getuserid = jwt_decode(localStorage.getItem("authToken"));
    user = getuserid?.id;
  } catch (e) {}
  const onVotes = async (id, type, team) => {
    var decoded = jwt_decode(localStorage.getItem("authToken"));
    try {
      const add = await axios.get(
        `${SERVER_URL}/add/votes?id=${id}&user=${decoded?.id}&type=${type}&team=${team}`
      );
      if (add?.data?.data?.error) {
        toast.error(add?.data?.data?.message);
      } else {
        toast.success(add?.data?.data?.message);
        await getVotes();
      }
    } catch (e) {}
  };

  const overallrate = votes?.filter((x) => x?.id == item?.id);

  const filterVotes = votes?.filter(
    (x) => x?.id == item?.id && x.user === user
  );

  const firtTeam = isfootball
    ? overallrate?.filter(
        (x) => x.team == item?.name?.substring(0, item?.name?.indexOf("vs"))?.trim()
      )
    : overallrate?.filter((x) => x.team == item?.localteam_id);
  const secondTeam = isfootball
    ? overallrate?.filter(
        (x) =>
          x.team ==
          item?.name
            .substring(item?.name.indexOf("vs"), item?.name?.length)
            ?.replace("vs", "")?.trim()
      )
    : overallrate?.filter((x) => x.team == `${item?.visitorteam_id}`);

  const drawTeam = overallrate?.filter((x) => x.team === "draw");

  const localteamPercentage = Math.round(
    (firtTeam?.length / overallrate?.length) * 100
  );

  const visitorteamPercentage = Math.round(
    (secondTeam?.length / overallrate?.length) * 100
  );

  const drawteamPercentage = Math.round(
    (drawTeam?.length / overallrate?.length) * 100
  );
  return (
    <div className='box cursor-pointer'>
      <div
        className=' cursor-pointer'
        onClick={() => {
          if (navigatable !== false && !isfootball) {
            navigate("/home/score", {
              state: {
                item,
                localteamscore,
                visitorteamscore,
              },
            });
          }
        }}
      >
        <div className='heading-container'>
          {!isMobile && (
            <div className='flex-row-heading'>
              <div className='fixture-match-title'>
                {" "}
                {isfootball
                  ? capitalizeFirstLetter(item?.name)
                  : capitalizeFirstLetter(item?.type)}
              </div>
              <div className='fixture-devider'>|</div>
              {/* <div className='fixture-time'>Match 09</div>
            <div className='fixture-devider'>|</div> */}
              <div className='fixture-date'>
                {" "}
                {moment(item?.starting_at)?.format("DD MMM YYYY")}
              </div>
              <div className='fixture-devider'>|</div>
              <div className='fixture-venue'>
                {
                  venues?.filter((x) => x?.data?.id === item?.venue_id)?.[0]
                    ?.data?.name
                }
              </div>
            </div>
          )}
          {isLive !== false && (
            <div className='live-tag'>
              <div className='live-tag-text'>• Live</div>
            </div>
          )}
        </div>
        <div className='space-between'>
          <div className='pd-10 flex-row-heading'>
            <Avatar
              src={
                isfootball
                  ? teams?.filter(
                      (g) =>
                        g?.type === "football" &&
                        g.id === item?.participants?.[0]?.id
                    )?.[0]?.image_path
                  : teams?.filter(
                      (g) =>
                        g?.type === "cricket" && g.id === item?.localteam_id
                    )?.[0]?.image_path
              }
              style={{ height: 24, width: 24, objectFit: "contain" }}
            />
            <div className='fixture-teamcode'>
              {" "}
              {isfootball
                ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                  ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                  : "-"
                : teams?.filter(
                    (g) => g.type === "cricket" && g.id === item?.localteam_id
                  )?.[0]?.name}
            </div>
          </div>
          {!isUpcoming && isfootball ? (
            <div className='flex-row-heading fixture-score'>
              {secondHalf?.filter(
                (x) => x.participant_id === item?.participants?.[0]?.id
              )?.[0]?.score?.goals || 0}{" "}
              goal
            </div>
          ) : (
            !isUpcoming && (
              <div className='score'>
                {localteamscore?.total || 0}/{localteamscore?.wickets || 0}{" "}
                <span className='over'>({localteamscore?.overs} Over)</span>
              </div>
            )
          )}
        </div>
        <div className='space-between'>
          <div className='pd-10 flex-row-heading'>
            <Avatar
              src={
                isfootball
                  ? teams?.filter(
                      (g) =>
                        g?.type === "football" &&
                        g.id === item?.participants?.[1]?.id
                    )?.[0]?.image_path
                  : teams?.filter(
                      (g) =>
                        g?.type === "cricket" && g.id === item?.visitorteam_id
                    )?.[0]?.image_path
              }
              style={{ height: 24, width: 24, objectFit: "contain" }}
            />

            <div className='fixture-teamcode'>
              {isfootball
                ? item?.name
                    .substring(item?.name.indexOf("vs"), item?.name?.length)
                    ?.replace("vs", "")
                  ? item?.name
                      .substring(item?.name.indexOf("vs"), item?.name?.length)
                      ?.replace("vs", "")
                  : "-"
                : teams?.filter(
                    (g) => g.type === "cricket" && g.id === item?.visitorteam_id
                  )?.[0]?.name}
            </div>
          </div>
          {!isUpcoming && isfootball ? (
            <div className='flex-row-heading fixture-score'>
              {secondHalf?.filter(
                (x) => x.participant_id === item?.participants?.[1]?.id
              )?.[0]?.score?.goals || 0}{" "}
              goal
            </div>
          ) : (
            !isUpcoming && (
              <div className='flex-row-heading fixture-score'>
                <div className='score'>
                  {visitorteamscore?.total || 0}/
                  {visitorteamscore?.wickets || 0}{" "}
                  <span className='over'>({visitorteamscore?.overs} Over)</span>
                </div>
              </div>
            )
          )}
        </div>
        {/* filterVotes?.[0]?.isWinner */}
      </div>
      <div className='fixture-note'>
        <div className='note-text'>{item?.note||item?.result_info}</div>

        {localStorage.getItem("authToken") &&
          showPrediction &&
          filterVotes?.length === 0 && (
            <div className='prediction-container1  prediction-row '>
              <div className=''>Who will win?</div>
              <div className='prediction-row'>
                <div
                  onClick={() => {
                    onVotes(
                      item?.id,
                      isfootball ? "football" : "cricket",
                      isfootball
                        ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                          ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                          : "-"
                        : item?.localteam_id
                    );
                  }}
                  className='prediction-button ml-10'
                >
                  {isfootball
                    ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                      ? item?.name?.substring(0, item?.name?.indexOf("vs"))
                      : "-"
                    : teams?.filter(
                        (g) =>
                          g.type === "cricket" && g.id === item?.localteam_id
                      )?.[0]?.name}
                </div>
                <div
                  onClick={() => {
                    onVotes(
                      item?.id,
                      isfootball ? "football" : "cricket",
                      isfootball
                        ? item?.name
                            .substring(
                              item?.name.indexOf("vs"),
                              item?.name?.length
                            )
                            ?.replace("vs", "")
                          ? item?.name
                              .substring(
                                item?.name.indexOf("vs"),
                                item?.name?.length
                              )
                              ?.replace("vs", "")
                          : "-"
                        : item?.visitorteam_id
                    );
                  }}
                  className='prediction-button ml-10'
                >
                  {isfootball
                    ? item?.name
                        .substring(item?.name.indexOf("vs"), item?.name?.length)
                        ?.replace("vs", "")
                      ? item?.name
                          .substring(
                            item?.name.indexOf("vs"),
                            item?.name?.length
                          )
                          ?.replace("vs", "")
                      : "-"
                    : teams?.filter(
                        (g) =>
                          g.type === "cricket" && g.id === item?.visitorteam_id
                      )?.[0]?.name}
                </div>
                <div
                  onClick={() => {
                    onVotes(
                      item?.id,
                      isfootball ? "football" : "cricket",
                      "draw"
                    );
                  }}
                  className='prediction-button ml-10'
                >
                  Draw
                </div>
              </div>
            </div>
          )}
        {filterVotes?.length > 0 && (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              width: "60%",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {filterVotes?.[0]?.isWinner && (
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "600",
                  color: "black",
                  marginRight: 10,
                }}
              >
                <img src={coin} /> 100 Jeet coins
              </div>
            )}

            <div style={{ width: "70%" }} className='prediction-container1'>
              <div className='prediction-row ' style={{ padding: 0 }}>
                <div className='success'>
                  {isfootball
                    ? item?.name?.substring(0, item?.name?.indexOf("vs"))?.trim()
                      ? item?.name?.substring(0, item?.name?.indexOf("vs"))?.trim()
                      : "-"
                    : teams?.filter(
                        (g) =>
                          g.type === "cricket" && g.id === item?.localteam_id
                      )?.[0]?.name
                    ? teams?.filter(
                        (g) =>
                          g.type === "cricket" && g.id === item?.localteam_id
                      )?.[0]?.name
                    : "--"}
                  ({localteamPercentage}%)
                  {isfootball &&
                    filterVotes?.[0]?.team ===
                      item?.name?.substring(0, item?.name?.indexOf("vs"))?.trim() && (
                      <img src={hand} />
                    )}
                  {!isfootball &&
                    `${filterVotes?.[0]?.team}` === `${item?.localteam_id}` && (
                      <img src={hand} />
                    )}
                </div>
                <div className='draw'>
                  DRAW MATCH({drawteamPercentage}%){" "}
                  {filterVotes?.[0]?.team === "draw" && <img src={hand} />}
                </div>
                <div className='low'>
                  {isfootball
                    ? item?.name
                        .substring(item?.name.indexOf("vs"), item?.name?.length)
                        ?.replace("vs", "")?.trim()
                      ? item?.name
                          .substring(
                            item?.name.indexOf("vs"),
                            item?.name?.length
                          )
                          ?.replace("vs", "")?.trim()
                      : "-"
                    : teams?.filter(
                        (g) =>
                          g.type === "cricket" && g.id === item?.visitorteam_id
                      )?.[0]?.name}
                  ({visitorteamPercentage}%)
                  {isfootball &&
                    filterVotes?.[0]?.team ===
                      item?.name.substring(
                        item?.name.indexOf("vs"),
                        item?.name?.length
                      )?.trim() && <img src={hand} />}
                  {!isfootball &&
                    `${filterVotes?.[0]?.team}` ===
                      `${item?.visitorteam_id}` && <img src={hand} />}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    display: "flex",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: `${localteamPercentage}%`,
                      backgroundColor: "green",
                      height: 10,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      padding: 10,
                    }}
                  ></div>

                  <div
                    style={{
                      position: "relative",
                      width: `${drawteamPercentage}%`,
                      backgroundColor: "gray",

                      height: 10,
                      padding: 10,
                    }}
                  ></div>

                  <div
                    style={{
                      position: "relative",
                      width: `${visitorteamPercentage}%`,
                      backgroundColor: "red",
                      height: 10,
                      padding: 10,
                      borderTopRightRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MatchTableCard;
