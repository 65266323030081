import React from "react";

import "antd/dist/reset.css";
import "./App.css";
import ViewportProvider from "./Context/ViewPortProvider";
import NewsProvider from "./Context/NewsProvider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Router from "./Routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "antd-country-phone-input/dist/index.css";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from 'world_countries_lists/data/countries/en/world.json';

const App = () => (
  <ConfigProvider locale={en}>
    <NewsProvider>
      <ViewportProvider>
        <Router />
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
      </ViewportProvider>
    </NewsProvider>
  </ConfigProvider>
);

export default App;
