const matchType = [
  {
    title: "All games",
    key: "all",
  },
  {
    title: "Cricket",
    key: "cricket",
  },
  {
    title: "Football",
    key: "football",
  },
  {
    title: "Kabaddi",
    key: "kabaddi",
  },
];
const homeTab = [
 
  {
    key: "live",
    label: `Live `,
  },
  {
    key: "upcoming",
    label: `Upcoming`,
  },
  {
    key: "recent",
    label: `Recent`,
  },
  
];

export { matchType, homeTab };
