import React from "react";
import { ViewportContext } from "../Context/ViewPortProvider";

const useViewport = () => {
  const { width, height } = React.useContext(ViewportContext);
  const isMobile = 900 > width;
  return { width, height, isMobile };
};

export default useViewport;
