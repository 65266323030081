import React from "react";
import { AppLayout } from "../../Components";

const Privacy = () => {
  return (
    <AppLayout>
      <div className='container'>
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: `<body class="c10 doc-content">
              <p class="c11"><span class="c8">Privacy Policy</span></p>
              <p class="c1"><span class="c0"></span></p>
              <p class="c3"><span class="c0">This Privacy Policy governs the manner in which Jeetwin.news collects, uses, maintains, and discloses information collected from users (each, a "User") of the Jeetwin.news website and mobile website (collectively, the "Site"). This Privacy Policy applies solely to the Site and all products and services offered by Jeetwin.news.</span></p>
              <p class="c1"><span class="c0"></span></p>
              <p class="c3"><span class="c0">Information Collection and Use:</span></p>
              <p class="c3"><span class="c0">Jeetwin.news does not collect any personally identifiable information from Users who access the Site for viewing fixtures and latest news related to cricket, football, and kabaddi. We do not require Users to log in or provide any personal information to access these features. Users can freely navigate and browse the Site without disclosing any personal data.</span></p>
              <p class="c1"><span class="c0"></span></p>
              <p class="c3"><span class="c0">Non-Personal Identification Information:</span></p>
              <p class="c3"><span class="c0">We may collect non-personal identification information about Users when they visit our Site. This information may include the browser name, the type of device used to access the Site, the operating system, the Internet service provider, and other similar technical information. This data is collected automatically and is used solely for statistical purposes to improve the Site's functionality and enhance the User experience.</span></p>
              <p class="c1"><span class="c0"></span></p>
              <p class="c3"><span class="c0">Web Browser Cookies:</span></p>
              <p class="c3"><span class="c0">Our Site may use cookies to enhance the User experience. Cookies are small text files stored on a User's device to track information about their visit. We may use cookies to remember User preferences, analyze Site usage patterns, and gather aggregate data to improve our content and services. Users can choose to disable cookies in their web browser settings, although this may affect the functionality of certain features on the Site.</span></p>
              <p class="c1"><span class="c0"></span></p>
              <p class="c3"><span class="c0">Third-Party Websites:</span></p>
              <p class="c3"><span class="c0">The Site may contain links to third-party websites for additional information or external resources. These third-party sites have their own privacy policies and terms of service. Jeetwin.news is not responsible for the practices employed by these websites. Users are encouraged to review the privacy policies of these third-party sites before providing any personal information.</span></p>
              <p class="c1"><span class="c0"></span></p>
              <p class="c3"><span class="c0">Data Security:</span></p>
              <p class="c3"><span class="c0">Jeetwin.news takes reasonable measures to protect the security and integrity of the information collected from Users. We implement appropriate data collection, storage, and processing practices, as well as security measures, to safeguard against unauthorized access, alteration, disclosure, or destruction of User data.</span></p>
              <p class="c1"><span class="c0"></span></p>
              <p class="c3"><span class="c0">Changes to this Privacy Policy:</span></p>
              <p class="c3"><span class="c0">Jeetwin.news reserves the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be reflected by updating the "Last updated" date at the bottom of this page. Users are encouraged to review this Privacy Policy periodically for any updates. By continuing to use the Site, Users acknowledge and agree to the current Privacy Policy.</span></p>
              <p class="c1"><span class="c0"></span></p>
              <p class="c3"><span class="c0">Contacting Us:</span></p>
              <p class="c3"><span class="c0">If you have any questions or concerns regarding this Privacy Policy or the practices of Jeetwin.news, please contact us on the details below:</span></p>
              <p class="c4"><span>WhatsApp Direct Chat Link: </span><span class="c5"><a class="c6" href="https://www.google.com/url?q=https://wa.link/70sz34&amp;sa=D&amp;source=editors&amp;ust=1686127049212956&amp;usg=AOvVaw0cuPqXkoooSYUeANrQ04bs">https://wa.link/70sz34</a></span></p>
              <p class="c4"><span class="c7">WhatsApp Number: +855 716916797</span></p>
              <p class="c4"><span>Email: </span><span class="c5"><a class="c6" href="mailto:support@jeetwin.com">support@Jeetwin.com</a></span><span>&nbsp;/ </span><span class="c5"><a class="c6" href="mailto:marketing@jeetwin.com">marketing@Jeetwin.com</a></span></p>
              <p class="c4 c9"><span class="c2"></span></p>
           </body>`,
            }}
          />
        </>
      </div>
    </AppLayout>
  );
};
export default Privacy;
