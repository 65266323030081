import axios from "axios";
import React, { useState } from "react";
import moment from "moment";
import _ from "lodash";
import jwt_decode from "jwt-decode";

import { SERVER_URL } from "../utils/server";
import Venues from "../utils/venues.json";
var parseString = require("react-native-xml2js").parseString;

export const NewsContext = React.createContext({});

const NewsProvider = ({ children }) => {
  const [feed, setFeed] = useState([]);
  const [news, setNews] = useState([]);
  const [footballnews, setFootballnews] = useState([]);
  const [kabaddinews, setKabaddinews] = useState([]);
  const [newsloading, setisLoading] = useState(false);

  const [livematch, setLivematch] = useState([]);
  const [recentmatch, setRecentmatch] = useState([]);
  const [upcominmatch, setUpcomingmatch] = useState([]);

  const [venues, setVenues] = useState([]);

  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [coindata, setCoindata] = useState([]);
  const [votes,setVotes] =useState([])
  // kabaddi
  const getNews = async () => {
    setisLoading(true);
    try {
      const data = await axios.get(`${SERVER_URL}/news?type=cricket`);
      parseString(data?.data?.data?.feed, (err, result) =>
        setFeed(result?.rss?.channel?.[0]?.item)
      );
      setisLoading(false);
      setNews(data?.data?.data?.cric);
    } catch (e) {
      setisLoading(false);
    }
  };
  const getFootballnews = async () => {
    setisLoading(true);
    try {
      const data = await axios.get(`${SERVER_URL}/news?type=football`);
      setisLoading(false);
      setFootballnews(data?.data?.data);
    } catch (e) {
      setisLoading(false);
    }
  };

  const getKabaddinews = async () => {
    setisLoading(true);
    try {
      const data = await axios.get(`${SERVER_URL}/news?type=kabaddi`);
      setisLoading(false);
      setKabaddinews(data?.data?.data);
    } catch (e) {
      setisLoading(false);
    }
  };

  const getPlayers = async () => {
    try {
      const data = await axios.get(`${SERVER_URL}/players`);
      setPlayers(data?.data?.data);
    } catch {}
  };
  const getTeams = async () => {
    try {
      const data = await axios.get(`${SERVER_URL}/teams`);

      setTeams(data?.data?.data);
    } catch (e) {}
  };

  const getLivematch = async () => {
    try {
      const data = await axios.get(SERVER_URL + "/live");
      setLivematch(
        data?.data?.data?.data?.sort(
          (a, b) =>
            new moment(a?.starting_at).format("YYYYMMDD") -
            new moment(b?.starting_at).format("YYYYMMDD")
        ) || []
      );
    } catch (e) {}
  };

  const getRecentmatch = async () => {
    try {
      const data = await axios.get(
        SERVER_URL +
          `/matches?type=recent&date=${moment().subtract(1, "year").calendar()}`
      );
      setRecentmatch(
        data?.data?.data?.sort(
          (a, b) =>
            new moment(b?.data?.starting_at).format("YYYYMMDD") -
            new moment(a?.data?.starting_at).format("YYYYMMDD")
        ) || []
      );
    } catch (e) {}
  };

  const getUpcomingmatch = async () => {
    try {
      const data = await axios.get(
        SERVER_URL +
          `/matches?type=upcoming&date=${moment().add(1, "year").calendar()}`
      );

      setUpcomingmatch(
        data?.data?.data?.sort(
          (a, b) =>
            new moment(a?.data?.starting_at).format("YYYYMMDD") -
            new moment(b?.data?.starting_at).format("YYYYMMDD")
        ) || []
      );
    } catch (e) {}
  };

  const getVenues = async () => {
    // try {
    //   const data = await axios.get(SERVER_URL + `/venues`);

    //   setVenues(data?.data?.data || []);
    // } catch (e) {}
    setVenues(Venues);
  };

  const getRefferal = async () => {
    if (localStorage.getItem("authToken")) {
      var decoded = jwt_decode(localStorage.getItem("authToken"));
      // setisLoading(true);
      try {
        const data = await axios.get(
          `${SERVER_URL}/get-referral?user=${decoded?.id}`
        );
        // setisLoading(false);
        setCoindata(data?.data?.data);
      } catch (e) {
        // setisLoading(false);
      }
    }
  };
  const getVotes = async () => {
    if (localStorage.getItem("authToken")) {
      try {
        const data = await axios.get(`${SERVER_URL}/votes`);

        setVotes(data?.data?.data);
      } catch (e) {}
    }
  };
  React.useEffect(() => {
    getNews();
    getFootballnews();
    getKabaddinews();
    getTeams();
    getLivematch();
    getRecentmatch();
    getUpcomingmatch();
    getVenues();
    getRefferal();
    getPlayers();
    getVotes();
  }, []);
  const feeds = feed?.map((x) => {
    const doc = new DOMParser().parseFromString(
      x?.description?.[0],
      "text/html"
    );
    return {
      description: x?.title?.[0],
      link: x?.link?.[0],
      title: x?.title?.[0],
      image: doc?.getElementsByTagName("img")?.[0]?.attributes?.[2]?.nodeValue,
    };
  });

  return (
    <NewsContext.Provider
      value={{
        news,
        feed: feeds,
        footballnews,
        kabaddinews,
        newsloading,
        teams,
        players,
        livematch,
        recentmatch,
        upcominmatch,
        venues,
        votes,
        getRefferal,
        getKabaddinews,
        getNews,
        getFootballnews,
        getTeams,
        getLivematch,
        getRecentmatch,
        getUpcomingmatch,
        getVenues,
        getPlayers,
        getVotes,
        coindata,
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};

export default NewsProvider;
