import React, { useContext, useEffect, useRef } from "react";
import { Col, Row, Tabs, Menu, Tag } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { AppLayout, MatchTableCard, Matchcard } from "../../Components";
import { homeTab, matchType } from "../../Constant";
import useViewport from "../../Hooks/useViewPort";
import { NewsContext } from "../../Context/NewsProvider";
import { shuffle } from "../../utils/helper";

const Fixtures = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useViewport();

  const {
    livematch,
    teams,
    recentmatch,
    upcominmatch,
    // news,
    // feed,
    // footballnews,
    // kabaddinews,
    // newsloading,
  } = useContext(NewsContext);
  const param = new URLSearchParams(location?.search);

  const onChange = (key) => {
    navigate(`?tab=${key}&match=${param.get("match")}`);
  };

  useEffect(() => {
    if (param?.get("tab") === null) {
      navigate(`?tab=live`);
    }
    if (param?.get("match") === null) {
      navigate(`?tab=live&match=all`);
    }
    // eslint-disable-next-line
  }, [param]);

  useEffect(() => {
    if (livematch?.length === 0) {
      navigate(`?tab=upcoming&match=all`);
    }
  }, [livematch]);

  // const allnews =
  //   param?.get("match") === "all"
  //     ? shuffle(news?.concat(feed)?.concat(footballnews)?.concat(kabaddinews))
  //     : param?.get("match") === "football"
  //     ? footballnews
  //     : param?.get("match") === "kabaddi"
  //     ? kabaddinews
  //     : news?.concat(feed);

  return (
    <AppLayout>
      <div className='container'>
        <Row gutter={16}>
          {isMobile ? (
            <Menu
              className='bg-black  color-yellow w-full'
              mode='horizontal'
              defaultSelectedKeys={[param?.get("match")]}
              onClick={(e) => {
                navigate(`?tab=${param?.get("tab")}&match=${e?.key}`);
              }}
              items={matchType.map((x) => {
                return {
                  key: x.key,
                  label: x.title,
                };
              })}
            />
          ) : (
            <>
              {matchType?.slice(0, 3)?.map((x, i) => {
                return (
                  <Col
                    onClick={() => {
                      navigate(`?tab=${param?.get("tab")}&match=${x?.key}`);
                    }}
                    key={i?.toString()}
                    span={4}
                  >
                    <Tag
                      className={
                        param.get("match") === x?.key ? "active-tag" : "tag"
                      }
                    >
                      <span>{x?.title}</span>
                    </Tag>
                  </Col>
                );
              })}
            </>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Tabs
              defaultActiveKey={param?.get("tab")}
              activeKey={param?.get("tab")}
              items={homeTab}
              onChange={onChange}
            />
            <div style={{ position: "relative" }}>
              {/* <MatchTableCard /> */}
              {param?.get("tab") === "live" && (
                // <Slider ref={sliderRef} {...matchsetting}>
                <div style={{ height: "70vh", overflow: "scroll" }} gutter={16}>
                  {livematch
                    ?.filter((g) =>
                      param.get("match") === "all"
                        ? true
                        : param.get("match") === "cricket"
                        ? g.type === "cricket"
                        : g.type === "football"
                    )
                    ?.filter((h) =>
                      h?.type === "football"
                        ? true
                        : h?.scoreboards?.length > 0 &&
                          teams?.filter(
                            (x) =>
                              x.type === "cricket" && x.id === h?.localteam_id
                          )?.[0]?.name !== undefined
                    )
                    ?.map((x) => {
                      return (
                        x !== null && (
                          <MatchTableCard
                            onlycol={true}
                            isfootball={x?.type === "football"}
                            item={x}
                            showPrediction={true}
                          />
                        )
                      );
                    })}
                </div>
              )}

              {param?.get("tab") === "upcoming" && (
                // <Slider ref={sliderRef} {...matchsetting}>
                <div style={{ height: "70vh", overflow: "scroll" }} gutter={16}>
                  {upcominmatch
                    ?.filter((g) =>
                      param.get("match") === "all"
                        ? true
                        : param.get("match") === "cricket"
                        ? g?.type === "cricket"
                        : param.get("match") === "football"
                        ? g?.type === "football"
                        : g?.type === "kabaddi"
                    )
                    ?.map((x) => {
                      return (
                        <MatchTableCard
                          isLive={false}
                          navigatable={false}
                          isUpcoming={true}
                          onlycol={true}
                          isfootball={x?.type === "football"}
                          item={x?.data}
                          showPrediction={true}
                        />
                      );
                    })}
                </div>
              )}
              {param?.get("tab") === "recent" && (
                <div style={{ height: "70vh", overflow: "scroll" }}>
                  {recentmatch
                    ?.filter((g) =>
                      param.get("match") === "all"
                        ? true
                        : param.get("match") === "cricket"
                        ? g?.type === "cricket"
                        : param.get("match") === "football"
                        ? g?.type === "football"
                        : g?.type === "kabaddi"
                    )
                    ?.map((x) => {
                      return (
                        <MatchTableCard
                          isLive={false}
                          showPrediction={false}
                          onlycol={true}
                          isfootball={x?.type === "football"}
                          item={x?.data}
                        />
                      );
                    })}
                </div>
              )}
            </div>

            {/* <Row gutter={16} className='mt-50'>
              <Col span={isMobile ? 24 : 18}>
                <div className='top-news-title'>Top News</div>
                {newsloading ? (
                  <>
                    <Skeleton.Image active={true} className='mt-10 home-news' />
                    <Skeleton />
                  </>
                ) : (
                  <Slider className='top-news' {...topnewssetting}>
                    {allnews
                      ?.filter(
                        (g) =>
                          g.image !== "" && !g?.image?.includes("undefined")
                      )
                      ?.slice(8, 12)
                      ?.map((x) => {
                        return (
                          <div
                            className='cursor-pointer'
                            onClick={() => {
                              navigatenewsdetails(x);
                            }}
                          >
                            <img
                              src={x?.image}
                              className='main-news-image'
                              alt='img'
                            />
                            <div className='main-news-title one-ellips mt-20'>
                              {x?.title}
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                )}

                <div className='top-news-title mb-20 mt-20'>
                  {param.get("match") === "all"
                    ? "All"
                    : param.get("match") === "cricket"
                    ? "Cricket"
                    : "Football"}
                </div>
                {newsloading ? (
                  <>
                    <Skeleton.Image active={true} className='mt-10 home-news' />
                    <Skeleton />
                  </>
                ) : (
                  <Slider className='top-news' {...othernewssetting}>
                    {allnews

                      ?.filter(
                        (g) =>
                          g.image !== "" && !g?.image?.includes("undefined")
                      )
                      ?.slice(0, 6)
                      ?.map((x) => {
                        return (
                          <div
                            className='cursor-pointer'
                            onClick={() => {
                              navigatenewsdetails(x);
                            }}
                          >
                            <img
                              src={x?.image}
                              className='small-news-card-image'
                              alt='img'
                            />
                            <div className='small-news-card-image-title two-ellips'>
                              {x?.title}
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                )}
              </Col>
              <Col span={isMobile ? 0 : 6}>
                <div className='detail-news-container1 ml-20'>
                  <div className='top-news-title'>Latest News</div>
                  {newsloading
                    ? [12, 4, 5, 6, 7].map((ff) => {
                        return (
                          <>
                            <Skeleton.Image
                              active={true}
                              className='mt-10 home-news'
                            />
                            <Skeleton />
                          </>
                        );
                      })
                    : allnews
                        ?.filter(
                          (g) =>
                            g.image !== "" && !g?.image?.includes("undefined")
                        )
                        ?.slice(7, 10)
                        ?.map((x) => {
                          return (
                            x?.description !== null && (
                              <>
                                <img
                                  alt='team'
                                  className='news-image-container'
                                  src={x?.image}
                                  style={{ width: "100%" }}
                                />
                                <div
                                  onClick={() => {
                                    navigatenewsdetails(x);
                                  }}
                                  className='news-single-title cursor-pointer'
                                >
                                  {x?.title}
                                </div>

                                <div className='devider mt-5 mb-5' />
                              </>
                            )
                          );
                        })}
                </div>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};
export default Fixtures;
