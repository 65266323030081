import React from "react";
import { AppLayout } from "../../Components";

const Terms = () => {
  return (
    <AppLayout>
      <div className='container'>
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: `<body class="c5 doc-content">
              <p class="c2"><span class="c6">Terms of Service:</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">These Terms of Service ("Terms") govern your access to and use of the Jeetwin.news website and mobile website (collectively, the "Site") and any products, features, applications, or services offered by Jeetwin.news (collectively, the "Services"). By accessing or using the Site or Services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not access or use the Site or Services.</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">Use of the Site and Services:</span></p>
              <p class="c2"><span class="c1">Jeetwin.news grants you a non-exclusive, non-transferable, revocable license to access and use the Site and Services for personal, non-commercial purposes. You agree not to use the Site or Services for any unlawful purpose or in any way that could damage, disable, overburden, or impair the Site or Services.</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">Intellectual Property Rights:</span></p>
              <p class="c2"><span class="c1">The Site and Services, including but not limited to text, graphics, images, videos, logos, and software, are owned by or licensed to Jeetwin.news and are protected by copyright, trademark, and other intellectual property laws. You agree not to modify, distribute, reproduce, publish, license, create derivative works from, or sell any content or materials obtained from the Site or Services without prior written consent from Jeetwin.news.</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">Content and Information:</span></p>
              <p class="c2"><span class="c1">The content and information provided on the Site, including fixtures, scores, and news related to cricket, football, and kabaddi, are for informational purposes only. Jeetwin.news does not guarantee the accuracy, completeness, or timeliness of such content. You acknowledge that reliance on any information provided on the Site is at your own risk.</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">Third-Party Links:</span></p>
              <p class="c2"><span class="c1">The Site may contain links to third-party websites or resources that are not owned or controlled by Jeetwin.news. Jeetwin.news has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites. You acknowledge and agree that Jeetwin.news is not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or resources.</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">Limitation of Liability:</span></p>
              <p class="c2"><span class="c1">In no event shall Jeetwin.news, its directors, officers, employees, or agents be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Site or Services. This includes, but is not limited to, damages for loss of profits, data, or other intangible losses, even if Jeetwin.news has been advised of the possibility of such damages.</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">Indemnification:</span></p>
              <p class="c2"><span class="c1">You agree to indemnify and hold harmless Jeetwin.news, its affiliates, officers, directors, employees, and agents from and against any claims, actions, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Site or Services or your violation of these Terms.</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">Modification and Termination:</span></p>
              <p class="c2"><span class="c1">Jeetwin.news reserves the right, at its sole discretion, to modify or terminate the Site or Services, or to modify these Terms, at any time without prior notice. Jeetwin.news shall not be liable to you or any third party for any modification or termination of the Site or Services or for any modification to these Terms.</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">Governing Law and Jurisdiction:</span></p>
              <p class="c2"><span class="c1">These Terms shall be governed by and construed in accordance with the laws of Maharashtra. Any dispute arising out of or relating to these Terms, the Site, or the Services shall be exclusively submitted to the competent courts of Maharashtra.</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">Entire Agreement:</span></p>
              <p class="c2"><span class="c1">These Terms constitute the entire agreement between you and Jeetwin.news regarding your use of the Site and Services and supersede all prior and contemporaneous agreements, proposals, or representations, whether oral or written.</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">Contact Us:</span></p>
              <p class="c2"><span class="c1">If you have any questions or concerns regarding these Terms, please contact us at the given details:</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span>WhatsApp Direct Chat Link: </span><span class="c3"><a class="c4" href="https://www.google.com/url?q=https://wa.link/70sz34&amp;sa=D&amp;source=editors&amp;ust=1686126972711568&amp;usg=AOvVaw3Ib2d2mBkwnnywcU4rufma">https://wa.link/70sz34</a></span></p>
              <p class="c2"><span class="c1">WhatsApp Number: +855 716916797</span></p>
              <p class="c2"><span>Email: </span><span class="c3"><a class="c4" href="mailto:support@jeetwin.com">support@Jeetwin.com</a></span><span>&nbsp;/ </span><span class="c3"><a class="c4" href="mailto:marketing@jeetwin.com">marketing@Jeetwin.com</a></span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c0"><span class="c7"></span></p>
           </body>`,
            }}
          />
        </>
      </div>
    </AppLayout>
  );
};
export default Terms;
