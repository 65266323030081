import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Input,
  Layout,
  Menu,
  Modal,
  Row,
  Space,
  Form,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import jwt_decode from "jwt-decode";
import CountryPhoneCode from "antd-country-phone-input";
import gif from "./gif.gif";
import logo from "../../assets/logo.png";
import facebook from "../../assets/facebook.png";
import twitter from "../../assets/twitter.png";
import referralpng from "../../assets/referral.png";
// import youtube from "../../assets/youtube.png";
import instagram from "../../assets/instagram.png";
import telegram from "../../assets/telegram.png";
import coinlarge from "../../assets/coinlarge.png";
import darkfacebook from "../../assets/darkfacebook.png";
import darktwitter from "../../assets/darktwitter.png";
// import darkyoutube from "../../assets/darkyoutube.png";
import darkinstagram from "../../assets/darkinstagram.png";
import darktelegram from "../../assets/darktelegram.png";
import useViewport from "../../Hooks/useViewPort";
import menu from "../../assets/menu.png";
import close from "../../assets/close.png";
import coin from "../../assets/coin.png";
import login from "../../assets/sport.png";
import axios from "axios";
import { SERVER_URL } from "../../utils/server";
import { toast } from "react-toastify";
import { NewsContext } from "../../Context/NewsProvider";

const { Header, Content, Footer } = Layout;

const AppLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile, width } = useViewport();
  const { getRefferal, coindata, votes } = useContext(NewsContext);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [loginshow, setLoginShow] = useState(false);
  const [verifyshow, setVerifyShow] = useState(false);
  const [showreferal, setShowreferral] = useState(false);
  const [getcoinshow, setGetcoinshow] = useState(false);
  const [currentCountry, setCurrentCountry] = useState("");

  const [isFirsttime, setisFirstTime] = useState(false);

  const [otp, setOtp] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileval, setMobileval] = useState({
    phone: "",
    code: 91,
    short: "IN",
  });

  const [code, setCode] = useState("");

  let user = "";
  try {
    var getuserid = jwt_decode(localStorage.getItem("authToken"));
    user = getuserid?.id;
  } catch (e) {}
  const filterVotes = votes
    ?.filter((x) => x.user === user)
    ?.filter((g) => g.isWinner === true);

  const onLogin = async () => {
    try {
      const data = await axios.get(`${SERVER_URL}/register?mobile=${mobile}`);

      if (data?.data?.data?.error) {
        toast.error(data?.data?.data?.message);
      } else {
        toast.success(data?.data?.data?.message);
        setisFirstTime(data?.data?.data?.isFirsttime);
        setLoginShow(false);
        setVerifyShow(true);
      }
    } catch (e) {
      toast.error("Try again after sometime");
    }
  };
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCurrentCountry(data?.country);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onVerifyotp = async () => {
    try {
      const data = await axios.get(
        `${SERVER_URL}/verify-otp?mobile=${mobile}&otp=${otp}`
      );

      if (data?.data?.data?.error) {
        toast.error(data?.data?.data?.message);
      } else {
        localStorage.setItem("authToken", data?.data?.data?.token);
        toast.success(data?.data?.data?.message);
        //
        setVerifyShow(false);

        if (isFirsttime) {
          setShowreferral(true);
        } else {
          getRefferal();
        }
      }
    } catch (e) {
      toast.error("Try again after sometime");
    }
  };

  const onReferrel = async () => {
    try {
      var decoded = jwt_decode(localStorage.getItem("authToken"));
      const data = await axios.get(
        `${SERVER_URL}/verify-referral?referral=${code}&user=${decoded?.id}`
      );

      if (data?.data?.data?.error) {
        toast.error(data?.data?.data?.message);
      } else {
        toast.success(data?.data?.data?.message);
        setShowreferral(false);
        setGetcoinshow(true);
        getRefferal();
      }
    } catch (e) {
      toast.error("Try again after sometime");
    }
  };
  const items = [
    // {
    //   label: "Refer And Earn",
    //   key: "1",
    // },
    {
      label: "Poll Result",
      key: "2",
    },
    {
      label: "Logout",
      key: "3",
    },
  ];

  useEffect(() => {
    getGeoInfo();
  }, []);
  return (
    <Layout>
      <Modal open={loginshow} centered mask={true} closable={false} footer={""}>
        <div
          className='bg-white '
          style={{
            borderRadius: 10,
            position: "relative",
            width: isMobile ? "100%" : window.innerWidth / 2,
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 9999,
              bottom: isMobile ? -50 : "unset",
              right: isMobile ? "45%" : -50,
              cursor: "pointer",
            }}
            onClick={() => {
              setLoginShow(!loginshow);
            }}
          >
            <img src={close} alt='close' />
          </div>
          <Row gutter={16}>
            <Col span={isMobile ? 0 : 8}>
              <img
                src={login}
                style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
              />
            </Col>

            <Col span={isMobile ? 24 : 16}>
              <div
                className='modal-login-vertical-center'
                style={{
                  padding: isMobile ? 15 : 20,
                  width: isMobile ? "100%" : "95%",
                }}
              >
                <div className='mb-10'>Enter mobile number</div>
                {/* <Input
                  size='large'
                  placeholder='Enter mobile number'
                  addonBefore='+91'
                  value={mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                />
                 */}
                <CountryPhoneCode
                  value={mobileval}
                  onChange={(v) => {
                    setMobileval(v);
                    setMobile(v.code + v.phone);
                  }}
                  placeholder='Enter mobile number'
                />
                <div className='mt-20'>
                  <span>
                    By sign in you agree to the{" "}
                    <span
                      onClick={() => {
                        window.open(
                          "https://www.jeetwin.news/terms-and-conditions",
                          "_blank"
                        );
                      }}
                      style={{
                        color: "#59626E",
                        cursor: "pointer",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {" "}
                      Terms & Conditions
                    </span>{" "}
                    and{" "}
                    <span
                      onClick={() => {
                        window.open(
                          "https://www.jeetwin.news/privacy-policy",
                          "_blank"
                        );
                      }}
                      style={{
                        color: "#59626E",
                        cursor: "pointer",
                        borderBottom: "1px solid black",
                      }}
                    >
                      Privacy Policy
                    </span>{" "}
                    of JeetWin
                  </span>
                </div>
                <div className='center mt-20'>
                  <Button
                    style={{
                      width: isMobile ? "100%" : 250,
                      background: "#F8BB22",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    size='large'
                    type='primary'
                    shape='default'
                    onClick={onLogin}
                  >
                    Request OTP
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={verifyshow}
        centered
        mask={true}
        closable={false}
        footer={""}
      >
        <div
          className='bg-white '
          style={{
            borderRadius: 10,
            position: "relative",
            width: isMobile ? "100%" : window.innerWidth / 2,
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 9999,
              bottom: isMobile ? -50 : "unset",
              right: isMobile ? "45%" : -50,
              cursor: "pointer",
            }}
            onClick={() => {
              setVerifyShow(false);
            }}
          >
            <img src={close} alt='close' />
          </div>
          <Row gutter={16}>
            <Col span={isMobile ? 0 : 8}>
              <img
                src={login}
                style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
              />
            </Col>

            <Col span={isMobile ? 24 : 16}>
              <div
                className='modal-login-vertical-center'
                style={{
                  padding: isMobile ? 15 : 20,
                  width: isMobile ? "100%" : "95%",
                }}
              >
                <div className='otp-title center'>Please enter OTP sent to</div>
                <div
                  className='otp-title center mt-10 mb-20'
                  style={{ fontWeight: "bold" }}
                >
                  +91 {mobile}{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#0067E3",
                      marginLeft: 5,
                    }}
                    onClick={() => {
                      setLoginShow(true);
                      setVerifyShow(false);
                    }}
                  >
                    Change
                  </span>
                </div>

                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  containerStyle={{
                    padding: isMobile ? 5 : 20,
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  inputStyle={{
                    width: isMobile ? "40px" : "90%",
                    height: 60,
                  }}
                  renderSeparator={
                    <span style={{ marginLeft: 10, marginRight: 10 }}></span>
                  }
                  renderInput={(props) => <Input {...props} />}
                />

                <div className='center mt-20'>
                  <Button
                    style={{
                      width: isMobile ? "100%" : 250,
                      background: "#F8BB22",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    size='large'
                    type='primary'
                    onClick={onVerifyotp}
                    shape='default'
                  >
                    Verify
                  </Button>
                </div>
                <div className='otp-title center mt-10 mb-20' style={{}}>
                  Not receive your code?
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#0067E3",
                      marginLeft: 5,
                    }}
                    onClick={async () => {
                      await axios.get(
                        `${SERVER_URL}/register?mobile=${mobile}`
                      );
                      toast.success("OTP sent to your mobile number");

                      // setLoginShow(true)
                      // setVerifyShow(false)
                    }}
                  >
                    Resend code
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={showreferal}
        centered
        mask={true}
        closable={false}
        footer={""}
      >
        <div
          className='bg-white '
          style={{
            borderRadius: 10,
            position: "relative",
            width: isMobile ? "100%" : window.innerWidth / 3,
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 9999,
              bottom: isMobile ? -50 : "unset",
              right: isMobile ? "45%" : -50,
              cursor: "pointer",
            }}
            onClick={() => {
              setShowreferral(false);
            }}
          >
            <img src={close} alt='close' />
          </div>
          <div
            className='modal-login-vertical-center'
            style={{
              padding: isMobile ? 20 : 20,
              width: isMobile ? "100%" : "95%",
            }}
          >
            <div className='otp-title center' style={{ fontSize: 22 }}>
              Have a referral code?
            </div>
            <div className='center'>
              <img
                style={{
                  objectFit: "contain",
                  height: 200,
                  width: 200,
                  marginTop: 10,
                }}
                src={referralpng}
              />
            </div>
            <Input
              size='large'
              style={{ marginLeft: 10 }}
              placeholder='Enter referral code'
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />

            <div className='center mt-20'>
              <Button
                style={{
                  width: isMobile ? "100%" : 250,
                  background: "#F8BB22",
                  color: "black",
                  fontWeight: "bold",
                }}
                size='large'
                onClick={() => {
                  onReferrel();
                }}
                type='primary'
                shape='default'
              >
                Verify
              </Button>
            </div>
            <div className='otp-title center mt-20 mb-20' style={{}}>
              I have no referral code?
              <span
                style={{
                  cursor: "pointer",
                  color: "#0067E3",
                  marginLeft: 5,
                }}
                onClick={() => {
                  // setLoginShow(true)
                  // setVerifyShow(false)
                  setShowreferral(false);
                }}
              >
                Skip
              </span>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={getcoinshow}
        centered
        mask={true}
        closable={false}
        footer={""}
      >
        <div
          className='bg-white '
          style={{
            borderRadius: 10,
            position: "relative",
            width: isMobile ? "100%" : window.innerWidth / 3,
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 9999,
              bottom: isMobile ? -50 : "unset",
              right: isMobile ? "45%" : -50,
              cursor: "pointer",
            }}
            onClick={() => {
              setGetcoinshow(false);
            }}
          >
            <img src={close} alt='close' />
          </div>
          <div
            className='modal-login-vertical-center'
            style={{
              padding: isMobile ? 15 : 20,
              width: isMobile ? "100%" : "95%",
            }}
          >
            <div className='otp-title center' style={{ fontSize: 22 }}>
              Hurray!
            </div>
            <div className='center'>
              <img
                style={{
                  objectFit: "contain",
                  height: 200,
                  width: 200,
                  marginTop: 10,
                }}
                src={coinlarge}
              />
            </div>

            <div className='otp-title center mt-20 mb-20' style={{}}>
              You got 100 Jeet coins
            </div>
          </div>
        </div>
      </Modal>
      <Header
        style={
          isMobile
            ? {
                height: 90,
                padding: "0px!important",
                position: "sticky",
                top: 0,
                zIndex: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
                // height: 20,
                background: "rgb(247 254 251)",
                padding: 20,
                cursor: "pointer",
                justifyContent: "center",
              }
            : {
                position: "sticky",
                top: 0,
                zIndex: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
                // height: 20,
                background: "rgb(247 254 251)",
                padding: 20,
                cursor: "pointer",
                justifyContent: "center",
              }
        }
        // onClick={() => {
        //   if (currentCountry === "BD") {
        //     window.open("https://jeet-winbd.com/signup", "_blank");
        //   } else {
        //     window.open("https://jeetwin.club/signup", "_blank");
        //   }
        // }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <img src={gif} style={{ height: 70 }} />
          <div
            style={isMobile ? { fontSize: 12, fontWeight: 500 } : {}}
            className='head-ad'
          >
            Get free 500 bonus. Win iPhone 14 & Free Credits.
          </div>
          {/* <img src={gif} style={{ height: 70 }} /> */}
          <Button
            style={{
              // width: isMobile ? "100%" : 250,
              background: "#F8BB22",
              color: "black",
              fontWeight: "bold",
              marginLeft: 10,
            }}
            onClick={() => {
              if (currentCountry === "BD") {
                window.open("https://jeet-winbd.com/signup", "_blank");
              } else {
                window.open("https://jeetwin.club/signup", "_blank");
              }
            }}
            size={isMobile ? "small" : "large"}
            type='primary'
            shape='default'
          >
            <div style={{ fontSize: isMobile ? 10 : 15 }}>Sign Up</div>
          </Button>
        </div>
      </Header>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          height: 80,
        }}
        className='bg-black'
      >
        <Drawer
          title=' '
          placement={"left"}
          width={width - 50}
          // onClose={onClose}
          open={openDrawer}
          closable={false}
          extra={
            <Space
              onClick={() => {
                setOpenDrawer(false);
              }}
              className='cursor-pointer'
            >
              <img src={close} alt='close' />
            </Space>
          }
        >
          <div className='center-vertical'>
            <div className='center' style={{ flexDirection: "column" }}>
              <div className='flex-row'>
                <img src={coin} />

                <div className=' ml-10'>
                  {(coindata?.length + filterVotes?.length) * 100}
                </div>
                <div className='coin-text ml-10'>Jeet Coins</div>
              </div>
              {!localStorage.getItem("authToken") && (
                <div
                  onClick={() => {
                    setLoginShow(true);
                    setOpenDrawer(false);
                  }}
                  className='menu-title cursor-pointer'
                >
                  LOGIN
                </div>
              )}
              <div
                onClick={() => {
                  navigate("/");
                }}
                className='menu-title cursor-pointer'
              >
                HOME
              </div>
              <div
                onClick={() => {
                  navigate("/fixtures");
                }}
                className='menu-title cursor-pointer'
              >
                FIXTURES
              </div>
              <div
                onClick={() => {
                  navigate("/news");
                }}
                className='menu-title cursor-pointer'
              >
                NEWS
              </div>
            </div>
            <div>
              <div className='menu-followus'>Follow us</div>
              <div className='space-between'>
                <img
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/jeetwinofficiall/",
                      "_blank"
                    );
                  }}
                  src={darkfacebook}
                  className='cursor-pointer'
                />
                <img
                  onClick={() => {
                    window.open(
                      " https://twitter.com/JeetWinOfficial",
                      "_blank"
                    );
                  }}
                  src={darktwitter}
                  className='ml-20 cursor-pointer'
                />
                <img
                  onClick={() => {
                    window.open(
                      "    https://www.instagram.com/jeetwinofficial/",
                      "_blank"
                    );
                  }}
                  src={darkinstagram}
                  className='ml-20 cursor-pointer'
                />
                <img
                  onClick={() => {
                    window.open(" https://t.me/jeetwin_official", "_blank");
                  }}
                  src={darktelegram}
                  className='ml-20 cursor-pointer'
                />
              </div>
            </div>
          </div>
        </Drawer>
        {isMobile && (
          <div
            onClick={() => {
              setOpenDrawer(true);
            }}
            className='cursor-pointer'
          >
            <img alt='menu' src={menu} />
          </div>
        )}
        <div
          onClick={() => {
            navigate("/");
          }}
          className={isMobile ? "center cursor-pointer" : "cursor-pointer"}
        >
          <img
            alt='logo'
            style={{ height: 65, marginLeft: isMobile ? -43 : "unset" }}
            src={logo}
          />
        </div>
        {!isMobile && (
          <Menu
            className='bg-black ml-50 color-yellow'
            mode='horizontal'
            defaultSelectedKeys={[
              location?.pathname?.includes("news")
                ? "/news"
                : location.pathname,
            ]}
            onClick={(e) => {
              navigate(e.key);
            }}
            style={{ width: "100%" }}
            items={[
              {
                key: "/",
                label: "Home",
              },
              {
                key: "/fixtures",
                label: "Fixtures",
              },
              {
                key: "/news",
                label: "News",
              },
            ]}
          />
        )}
        {!isMobile && (
          <div className='social-icon'>
            <div className='flex-row' style={{ width: 200 }}>
              <img src={coin} />

              <div className='coin ml-10'>
                {(coindata?.length + filterVotes?.length) * 100}
              </div>
              <div className='coin-text ml-10'>Jeet Coins</div>
            </div>
            {!localStorage.getItem("authToken") && (
              <Button
                style={{
                  width: 150,
                  background: "#F8BB22",
                  color: "black",
                  fontWeight: "bold",
                }}
                size='large'
                type='primary'
                shape='default'
                onClick={() => {
                  setLoginShow(!loginshow);
                }}
              >
                LOGIN
              </Button>
            )}
            {localStorage.getItem("authToken") && (
              <Dropdown
                menu={{
                  items,
                  onClick: (e) => {
                    if (e?.key === "3") {
                      localStorage.clear();
                      window.location.reload();
                    } else {
                      navigate("/poll-results");
                    }
                  },
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontSize: 14,
                    textAlign: "center",
                    cursor: "pointer",
                    width: 100,
                  }}
                  onClick={(e) => e.preventDefault()}
                >
                  My Profile
                </div>
              </Dropdown>
            )}
          </div>
        )}
      </Header>
      <Content className='bg-white'>{children}</Content>
      <Footer style={{ justifyContent: isMobile ? "center" : "" }}>
        <div className={isMobile ? "" : "space-between w-full"}>
          <div
            className={
              isMobile ? "mt-20 color-white text-center" : "color-white"
            }
          >
            @JeetWin News | All rights reserved
            <div
              className={
                isMobile
                  ? "mt-20 color-white text-center"
                  : "color-white text-center mt-10"
              }
            >
              For more enquiries email us at{" "}
              <span
                onClick={() => {
                  window.open(`mailto:info@jeetwin.news`);
                }}
                style={{ display: isMobile ? "none" : "" }}
                className='color-yellow text-center cursor-pointer'
              >
                info@jeetwin.news
              </span>
            </div>
          </div>

          <div
            style={{ display: isMobile ? "" : "none" }}
            onClick={() => {
              window.open(`mailto:info@jeetwin.news`);
            }}
            className='color-yellow text-center mt-5 ursor-pointer'
          >
            info@jeetwin.news
          </div>
          <div className={isMobile ? "" : "space-between "}>
            <div
              onClick={() => {
                window.scroll({
                  top: 0,
                  left: 100,
                  behavior: "smooth",
                });
                navigate("/terms-and-conditions");
              }}
              className={
                isMobile
                  ? "mt-20 color-white text-center cursor-pointer"
                  : "color-white text-center cursor-pointer"
              }
            >
              {" "}
              Terms & Conditions{" "}
            </div>
            <div
              onClick={() => {
                window.scroll({
                  top: 0,
                  left: 100,
                  behavior: "smooth",
                });
                navigate("/privacy-policy");
              }}
              className={
                isMobile
                  ? "mt-20 color-white text-center cursor-pointer"
                  : "color-white text-center ml-10 cursor-pointer"
              }
            >
              {" "}
              Privacy Policy{" "}
            </div>
            {/* <div
              className={
                isMobile
                  ? "mt-20 color-white text-center"
                  : "color-white text-center ml-10"
              }
            >
              {" "}
              Cookie Policy
            </div> */}
          </div>
          {!isMobile && (
            <div className='space-between'>
              <img
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/jeetwinofficiall/",
                    "_blank"
                  );
                }}
                src={facebook}
                className='cursor-pointer'
              />
              <img
                onClick={() => {
                  window.open(" https://twitter.com/JeetWinOfficial", "_blank");
                }}
                src={twitter}
                className='ml-20 cursor-pointer'
              />
              <img
                onClick={() => {
                  window.open(
                    "    https://www.instagram.com/jeetwinofficial/",
                    "_blank"
                  );
                }}
                src={instagram}
                className='ml-20 cursor-pointer'
              />
              <img
                onClick={() => {
                  window.open(" https://t.me/jeetwin_official", "_blank");
                }}
                src={telegram}
                className='ml-20 cursor-pointer'
              />
            </div>
          )}
        </div>
      </Footer>
    </Layout>
  );
};

export default AppLayout;
