import React, { useContext, useEffect } from "react";
import { Col, Row, Divider, Menu, Tag, Skeleton } from "antd";
import Slider from "react-slick";

import { AppLayout } from "../../Components";
import { useLocation, useNavigate } from "react-router-dom";
import useViewport from "../../Hooks/useViewPort";
import { matchType } from "../../Constant";
import { NewsContext } from "../../Context/NewsProvider";
import { shuffle } from "../../utils/helper";
import { othernewssetting, topnewssetting } from "../../utils/slider";

const News = () => {
  const { isMobile } = useViewport();
  const location = useLocation();
  const navigate = useNavigate();
  const param = new URLSearchParams(location?.search);
  const { news, feed, footballnews, kabaddinews, newsloading } =
    useContext(NewsContext);

  useEffect(() => {
    if (param?.get("match") === null) {
      navigate(`?match=all`);
    }
    // eslint-disable-next-line
  }, [param]);

  const navigatenewsdetails = (x) => {
    navigate(`/news/details`, {
      state: {
        ...x,
        type: x?.link?.includes("sky24")
          ? "sky24"
          : x?.link?.includes("express")
          ? "express"
          : x?.link?.includes("cricbuzz")
          ? "cricbuzz"
          : x?.link?.includes("prokabaddi")
          ? "kabaddi"
          : x?.link?.includes("goal")
          ? "football"
          : "",
      },
    });
  };
  const allnews =
    param?.get("match") === "all"
      ? shuffle(news?.concat(feed)?.concat(footballnews)?.concat(kabaddinews))
      : param?.get("match") === "football"
      ? footballnews
      : param?.get("match") === "kabaddi"
      ? kabaddinews
      : news?.concat(feed);
  return (
    <AppLayout>
      <div className='container'>
        <Row gutter={16}>
          {isMobile ? (
            <Menu
              className='bg-black  color-yellow w-full'
              mode='horizontal'
              defaultSelectedKeys={[param?.get("match")]}
              onClick={(e) => {
                navigate(`?match=${e?.key}`);
              }}
              items={matchType.map((x) => {
                return {
                  key: x.key,
                  label: x.title,
                };
              })}
            />
          ) : (
            <>
              {matchType?.map((x, i) => {
                return (
                  <Col
                    onClick={() => {
                      navigate(`?match=${x?.key}`);
                    }}
                    key={i?.toString()}
                    span={4}
                  >
                    <Tag
                      className={
                        param.get("match") === x?.key ? "active-tag" : "tag"
                      }
                    >
                      <span>{x?.title}</span>
                    </Tag>
                  </Col>
                );
              })}
            </>
          )}
        </Row>
        {news?.length > 0 ? (
          <Row gutter={16}>
            <Col span={isMobile ? 24 : 18}>
              <div className='match-heading mt-20'>News</div>

              <div className='devider mt-10 mb-10' />
              <div className='top-news-title'>Top News</div>
              {newsloading ? (
                <>
                  <Skeleton.Image active={true} className='mt-10 home-news' />
                  <Skeleton />
                </>
              ) : (
                <Slider className='top-news' {...topnewssetting}>
                  {allnews
                    ?.filter(
                      (g) =>
                        g.image !== "" &&
                        g.image !== undefined &&
                        g.image !== "undefined" &&
                        g.image !== null &&
                        !g?.image?.includes("undefined")
                    )
                    ?.slice(8, 12)
                    ?.map((x) => {
                      return (
                        <div
                          className='cursor-pointer'
                          onClick={() => {
                            navigatenewsdetails(x);
                          }}
                        >
                          <img
                            src={
                              x?.image?.includes("cricbuzz")
                                ? x?.image?.replace("100x77", "700x400")
                                : x?.image?.includes("goal")
                                ? x?.image?.replace("quality=60", "quality=100")
                                : x?.image?.includes("w=")
                                ? x?.image?.replace("w=400", "w=700")
                                : x?.image?.includes("resize")
                                ? x?.image?.replace(
                                    "?resize=270,152",
                                    "?resize=800,400"
                                  )
                                : x?.image
                            }
                            className='main-news-image'
                            alt='img'
                          />
                          <div className='main-news-title one-ellips mt-20'>
                            {x?.title}
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              )}
              {param?.get("match") === "all" && (
                <>
                  <div className='top-news-title mb-20 mt-20'>Cricket</div>
                  {newsloading ? (
                    <>
                      <Skeleton.Image
                        active={true}
                        className='mt-10 home-news'
                      />
                      <Skeleton />
                    </>
                  ) : (
                    <Slider
                      className='top-news slider-new-single'
                      {...othernewssetting}
                    >
                      {news
                        ?.concat(feed)
                        ?.filter(
                          (g) =>
                            g.image !== "" &&
                            g.image !== undefined &&
                            g.image !== "undefined" &&
                            g.image !== null &&
                            !g?.image?.includes("undefined")
                        )
                        ?.slice(0, 6)
                        ?.map((x) => {
                          return (
                            <div
                              className='cursor-pointer'
                              onClick={() => {
                                navigatenewsdetails(x);
                              }}
                            >
                              <img
                                src={x?.image}
                                className='small-news-card-image'
                                alt='img'
                                style={{
                                  width: "100%",
                                  borderRadius: 10,
                                  objectFit: "cover",
                                }}
                              />
                              <div className='news-single-title cursor-pointer   three-dot'>
                                {x?.title}
                              </div>
                            </div>
                          );
                        })}
                    </Slider>
                  )}
                  <div className='devider mt-10 mb-10' />

                  <div className='top-news-title mb-20 mt-20'>Football</div>
                  {newsloading ? (
                    <>
                      <Skeleton.Image
                        active={true}
                        className='mt-10 home-news'
                      />
                      <Skeleton />
                    </>
                  ) : (
                    <Slider
                      className='top-news slider-new-single'
                      {...othernewssetting}
                    >
                      {footballnews
                        ?.filter(
                          (g) =>
                            g.image !== "" &&
                            g.image !== undefined &&
                            g.image !== "undefined" &&
                            g.image !== null &&
                            !g?.image?.includes("undefined")
                        )
                        ?.slice(0, 6)
                        ?.map((x) => {
                          return (
                            <div
                              className='cursor-pointer'
                              onClick={() => {
                                navigatenewsdetails(x);
                              }}
                            >
                              <img
                                src={x?.image}
                                className='small-news-card-image'
                                alt='img'
                                style={{
                                  width: "100%",
                                  borderRadius: 10,
                                  objectFit: "cover",
                                }}
                              />
                              <div className='news-single-title cursor-pointer   three-dot'>
                                {x?.title}
                              </div>
                            </div>
                          );
                        })}
                    </Slider>
                  )}
                  <div className='devider mt-10 mb-10' />
                  <div className='top-news-title mb-20 mt-20'>Kabbadi</div>
                  {newsloading ? (
                    <>
                      <Skeleton.Image
                        active={true}
                        className='mt-10 home-news'
                      />
                      <Skeleton />
                    </>
                  ) : (
                    <Slider
                      className='top-news slider-new-single'
                      {...othernewssetting}
                    >
                      {kabaddinews
                        ?.filter(
                          (g) =>
                            g.image !== "" &&
                            g.image !== undefined &&
                            g.image !== "undefined" &&
                            g.image !== null &&
                            !g?.image?.includes("undefined")
                        )
                        ?.slice(0, 6)
                        ?.map((x) => {
                          return (
                            <div
                              className='cursor-pointer'
                              onClick={() => {
                                navigatenewsdetails(x);
                              }}
                            >
                              <img
                                src={x?.image}
                                className='small-news-card-image'
                                alt='img'
                                style={{
                                  width: "100%",
                                  borderRadius: 10,
                                  objectFit: "cover",
                                }}
                              />
                              <div className='news-single-title cursor-pointer   three-dot'>
                                {x?.title}
                              </div>
                            </div>
                          );
                        })}
                    </Slider>
                  )}
                </>
              )}
              {param?.get("match") !== "all" && (
                <>
                  <div className='top-news-title mb-20 mt-20'>
                    {param.get("match") === "cricket"
                      ? "Cricket"
                      : param.get("match") === "football"
                      ? "Football"
                      : "Kabaddi"}
                  </div>
                  <Row className='top-news slider-new-single' gutter={16}>
                    {allnews
                      ?.filter(
                        (g) =>
                          g.image !== "" &&
                          g.image !== undefined &&
                          g.image !== "undefined" &&
                          g.image !== null &&
                          !g?.image?.includes("undefined")
                      )
                      ?.map((x) => {
                        return (
                          <Col span={isMobile ? 24 : 8}>
                            <div
                              className='cursor-pointer'
                              style={{ marginTop: 10, marginBottom: 10 }}
                              onClick={() => {
                                navigatenewsdetails(x);
                              }}
                            >
                              <img
                                src={x?.image}
                                className='small-news-card-image'
                                alt='img'
                                style={{
                                  width: "100%",
                                  borderRadius: 10,
                                  objectFit: "cover",
                                }}
                              />
                              <div className='news-single-title cursor-pointer   three-dot'>
                                {x?.title}
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                </>
              )}
            </Col>
            <Col span={isMobile ? 0 : 6}>
              <div className='detail-news-container1 mt-20 ml-20'>
                <div className='top-news-title'>Latest News</div>
                {newsloading
                  ? [12, 4, 5, 6, 7].map((ff) => {
                      return (
                        <>
                          <Skeleton.Image
                            active={true}
                            className='mt-10 home-news'
                          />
                          <Skeleton />
                        </>
                      );
                    })
                  : shuffle(allnews)
                      ?.filter(
                        (g) =>
                          g.image !== "" &&
                          g.image !== undefined &&
                          g.image !== "undefined" &&
                          g.image !== null &&
                          !g?.image?.includes("undefined")
                      )
                      ?.slice(0, 8)
                      ?.map((x) => {
                        return (
                          x?.description !== null && (
                            <>
                              <img
                                alt='team'
                                className='news-image-container'
                                src={x?.image}
                                style={{
                                  width: "100%",
                                  borderRadius: 10,
                                  objectFit: "cover",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(`/news/details`, {
                                    state: {
                                      ...x,
                                      type: x?.link?.includes("sky24")
                                        ? "sky24"
                                        : x?.link?.includes("express")
                                        ? "express"
                                        : x?.link?.includes("cricbuzz")
                                        ? "cricbuzz"
                                        : x?.link?.includes("prokabaddi")
                                        ? "kabaddi"
                                        : x?.link?.includes("goal")
                                        ? "football"
                                        : "",
                                    },
                                  });
                                }}
                              />
                              <div
                                onClick={() => {
                                  navigate(`/news/details`, {
                                    state: {
                                      ...x,
                                      type: x?.link?.includes("sky24")
                                        ? "sky24"
                                        : x?.link?.includes("express")
                                        ? "express"
                                        : x?.link?.includes("cricbuzz")
                                        ? "cricbuzz"
                                        : x?.link?.includes("prokabaddi")
                                        ? "kabaddi"
                                        : x?.link?.includes("goal")
                                        ? "football"
                                        : "",
                                    },
                                  });
                                }}
                                className='news-single-title cursor-pointer mt-10 mb-10 two-ellips1'
                              >
                                {x?.title}
                              </div>

                              <div className='devider mt-10 mb-10' />
                            </>
                          )
                        );
                      })}
              </div>
            </Col>
          </Row>
        ) : (
          <Row gutter={16}>
            <Col span={isMobile ? 24 : 18}>
              <div className='top-news-title'>Top News</div>

              <Skeleton.Image active={true} className='mt-10 home-news' />
              <Skeleton />
            </Col>
            <Col span={isMobile ? 24 : 18}>
              <Skeleton.Image active={true} className='mt-10 home-news' />
              <Skeleton />
            </Col>
            <Col span={isMobile ? 24 : 18}>
              <Skeleton.Image active={true} className='mt-10 home-news' />
              <Skeleton />
            </Col>
          </Row>
        )}
      </div>
    </AppLayout>
  );
};
export default News;
